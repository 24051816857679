.container {
  position: relative;
  width: 100vw;
  height: 100vh;
  background-color: #566c7f;
  background-image: url(background.svg);
  overflow: hidden;
}

.card {
  text-align: center;
  color: var(--text-dark);
  position: relative;
  top: 200px;
  width: 660px;
  padding: 40px 40px;
  box-sizing: border-box;
  background: white;
  border-radius: 6px;
  margin: 0 auto;
}

.background_layout {
  position: relative;
  width: 100%;
  max-width: 1200px;
  height: 100%;
  margin: 0 auto;
}

@media (max-height: 800px) {
  .card {
    top: 100px;
  }
}

@media (max-height: 620px) {
  .card {
    padding: 20px;
  }
}
@media (max-height: 550px) {
  .card {
    top: 80px;
  }
}
