.map {
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: 100vw;
  height: 500px;
  background: white;
  overflow: hidden;
}

.loader {
  position: absolute;
  width: 100%;
}

.image {
  position: absolute;
  top: 0;
  left: 0;
  filter: brightness(0.5);
}

.text {
  z-index: 20;
  color: var(--text-dark);
  position: absolute;
  top: 250px;
  width: 100%;
  text-align: center;
}

@media (max-height: 900px) {
  .map {
    height: 370px;
  }
  .text {
    top: 20vh;
  }
}
@media (max-height: 800px) {
  .map {
    height: 50vh;
  }
}
@media (max-height: 700px) {
  .map {
    height: 45vh;
  }
}
