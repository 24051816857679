.buttons {
  position: absolute;
  top: 50px;
  left: 0px;
  width: 560px;
  border-radius: 6px;
  height: 180px;
  display: flex;
  flex-direction: column;
  /* padding: 40px 20px; */
  background: #fff;
  filter: drop-shadow(1px 1px 2px rgb(207, 236, 250));
  z-index: 22;
}

.tabs {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.tab.selected.button {
  /* background: var(--primary-orange) !important;
   */
  background: white;
  border: 1px solid var(--primary-orange);
  color: var(--text-dark);
}

.tab {
  flex-grow: 1;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;
  background: #f7f7f7;
  border: 1px solid #ffffff;
}

.tab.selected {
  background: #ffffff;
}

.content {
  width: 100%;
  height: 140px;
  padding: 24px 0px;
  box-sizing: border-box;
  color: var(--text-dark);
}

.button {
  width: 300px;
  height: 82px;
  border-radius: 6px;
  border: none;
  outline: none;
  background: var(--buttons-dark);
  color: var(--text-bright);
  margin-bottom: 20px;
  cursor: pointer;
  transition-duration: 300ms;
  transition-property: transform;
  display: flex;
  align-items: center;
}

button:hover {
  transform: scale(1.02);
}

.card {
  display: flex;
  align-items: center;
  padding: 0px 20px;
}

.icon {
  min-width: 32px;
  max-width: 32px;
  display: flex;
  align-items: center;
}

.text {
  height: 100px;
  padding-left: 24px;
}
.title {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}

.description {
  width: 95%;
  color: var(--text-dark-lighten3);
}

@media (max-height: 700px) {
  .buttons {
    width: 310px;
    height: 240px;
  }
  .description {
    width: 85%;
    color: var(--text-dark-lighten3);
  }
}
