.m-projects {
    position: relative;
    background-color: #63A6AF;
    width: 100vw;
    height: 605px;
    padding-top: 20px;
}


.m-projects-items {
    position: relative;
    width: 100vw;
    height: 365px;
    overflow: hidden;
    margin-top: 20px;
}

.m-projects-button {
    margin: 0 auto;
    width: 224px;
    height: 48px;
    background-color: #455158;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
}

.m-projects-fader {
    width: 100vw;
    height: 25px;
    background-color: #63a6afb7;
    position: absolute;
    bottom: 0px;
}

.m-projects-footer {
    width: 100vw;
    height: 140px;
    background-color: #BCD4E6;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 0px;
}

.m-projects-all {
    width: 100vw;
    height: 100vh;
    overflow-y: scroll;
    padding: 60px 0 140px 0;
    /* margin-bottom: 100px; */
    box-sizing: border-box;
}

.m-projects-close {
    position: absolute;
    top: 16px;
    right: 23px;
    height: 30px;
    width: 30px;
}

.m-projects-bg {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100vw;
}

.m-projects-loading {
    position: absolute;
    top: 200px;
    text-align: center;
    width: 100%;
}