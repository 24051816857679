.m-key {
  position: relative;
  height: 900px;
  background-color: rgb(27, 27, 27);
  width: 100vw;
  /* padding-bottom: 60px; */
}

.m-key-bg {
  position: absolute;
  width: 100vw;
  min-height: 900px;
  max-height: 900px;
  overflow: hidden;
  box-sizing: border-box;
  padding-top: 120px;
}

.m-key-bg img {
  position: absolute;
  width: 100vw;
  min-width: 320px;
  top: 0px;
  left: 0px;
}

.m-key-ship {
  width: 100vw;
  max-width: 100%;
  height: 100px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  /* animation: ship-income 2s 1; */
}

.m-key-ship img {
  animation: m-ship 6s infinite;
}

/* @keyframes ship-income {
    0% {
        transform: translateX(100vw)
    }
    100% {
        transform: translateX(0);
    }
    
} */

@keyframes m-ship {
  0% {
    transform: translateY(20px);
  }
  50% {
    transform: translateY(30px);
  }
  100% {
    transform: translateY(20px);
  }
}
.m-key-ship img {
  width: 80vw;
  max-width: 500px;
  position: relative;
  right: 0;
}
.m-key-title {
  position: relative;
  width: 100vw;
  height: 80px;
  background-color: white;
  z-index: 10000;
}
.m-key-items {
  width: 100vw;
  position: relative;
  height: 440px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.m-key-modal {
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 10000;
  width: 100vw;
  height: 100vh;
  min-height: 500px;
  background-color: #3e515d;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.m-key-modal .m-key-close {
  width: 90vw;
  height: 40px;
  min-height: 40px;
  max-height: 40px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.m-key-modal .m-key-close img {
}

.m-key-modal h2 {
  width: 100vw;
  height: 80px;
  min-height: 80px;
  font-size: 1.3em;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #daeaf6;
  color: #49525e;
}
.m-key-modal p {
  width: 100vw;
  height: 40vh;
  max-height: 400px;
  display: block;
  margin: 10vh auto 0 auto;
  text-align: center;
  box-sizing: border-box;
  padding: 0 20px;
  max-width: 400px;
  color: white;
}

.m-key-modal button {
  width: 222px;
  min-height: 48px;
  height: 48px;
  background: #3f3f3f;
  border-radius: 6px;
  color: white;
  border: none;
  filter: drop-shadow(0px 2px 1px rgb(55, 55, 55));
  display: block;
  margin: 0 auto;
  position: absolute;
  bottom: 100px;
}

.m-key-footer {
  width: 100vw;
  height: 260px;
  bottom: 0px;
  position: absolute;
  background-color: #daeaf6;
  overflow: hidden;
}

.m-key-button {
  width: 222px;
  min-height: 48px;
  height: 48px;
  background: #3f3f3f;
  border-radius: 6px;
  color: white;
  border: none;
  filter: drop-shadow(0px 2px 1px rgb(55, 55, 55));
  display: block;
  margin: 40px auto 0px auto;
  position: relative;
}

.m-key-rails {
  position: relative;
  width: 100vw;
  max-width: 100%;
  min-width: 400px;
  top: -6px;
  z-index: 900;
}

.m-key-train {
  width: 100vw;
  height: 140px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
  z-index: 950;
  animation: m-train 10s infinite;
}

@keyframes m-train {
  0% {
    transform: translateX(-110vw) translateY(4px);
  }
  50% {
    transform: translateX(-100vw) translateY(4px);
  }
  100% {
    transform: translateX(-110vw) translateY(4px);
  }
}

/* .m-key-train  {
    width: 50vw;
    max-width: 340px;
    min-width: 240px;
    position: relative;
    left: 0;
} */

.m-key-tree-front {
  position: absolute;
  width: 100vw;
  top: 144px;
  left: 0px;
  z-index: 10000;
  animation: m-trees 3s infinite linear;
  box-sizing: border-box;
  padding: 0 80px;
}

.m-key-tree-back {
  position: absolute;
  width: 100vw;
  top: 124px;
  left: 0px;
  animation: m-trees 3s infinite linear;
  box-sizing: border-box;
  padding: 0 60px;
}

.m-key-tree-front-2 {
  position: absolute;
  width: 100vw;
  top: 144px;
  z-index: 10000;
  left: 100vw;
  animation: m-trees 3s infinite linear;
  box-sizing: border-box;
  padding: 0 80px;
}

.m-key-tree-back-2 {
  position: absolute;
  width: 100vw;
  top: 124px;
  left: 100vw;
  animation: m-trees 3s infinite linear;
  box-sizing: border-box;
  padding: 0 60px;
}

@keyframes m-trees {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100vw);
  }
}
