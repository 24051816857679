.a-main-inputs {
    margin: 40px auto;
    width: 80%;
    display: flex;
    flex-direction: column;
}

.a-main-textarea {
    width: 80%;
    height: 120px;
    resize: none;
    margin-bottom: 24px;
    font-size: 19px;
    padding: 12px;
    box-sizing: border-box;
}

.a-main-input {
    width: 80%;
    height: 52px;
    margin-bottom: 24px;
    font-size: 16px;
    padding: 12px;
    box-sizing: border-box;
}

.a-main-title {
    margin-bottom: 8px;
    margin-left: 12px;
}

.a-main-save-button-container {
    display: flex;
    align-items: center;
    margin-top: 20px;

}

.a-main-save-button {
    margin-right: 24px;
    width: 220px;
    height: 52px;
    color: white;
    background-color: rgb(18, 155, 59);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition-duration: 300ms;
}

.a-main-save-button:active {
    transform: scale(0.95);
}

.a-main-error {
    color: rgb(129, 43, 43);
    transition-duration: 500ms;

}

.a-main-success {
    color: rgb(43, 129, 69);
    transition-duration: 500ms;

}

.a-main-warning {
    color: rgb(129, 128, 43);
    transition-duration: 500ms;

}


.l-yandex > input {
    width: 80%;
    height: 52px;
    margin-bottom: 24px;
    font-size: 16px;
    padding: 12px;
    box-sizing: border-box;
}

.l-yandex > p {
    width: 100%;
    height: 24px;
    font-size: 16px;
    color: #657486;
    text-align: left;
    padding-left: 12px;
    margin-bottom: 4px;
}

.l-yandex-map {
    position: relative;
    margin-top: 20px;
    height: 260px;
    width: 452px;
    background: white; 
    border: 1px solid black;
    display: flex;
    align-items: center;
    justify-content: center;
}

.l-yandex-map > p {    
    position: absolute;
    top: 120px;
    left: 155px;
}