.m-team {
    position: relative;
    height: 760px;
    padding-top: 30px;
    background-color: #DAEAF6;
    width: 100vw;
}

.m-team-slider {
    overflow: hidden;
    position: relative;
    width: 100vw;
    height: 770px;
}

.m-team-slider-bg {
    position: absolute;
    left: -20vw;
    width: 140vw;
    height: 140vw;
    margin-top: 40px;
    border-radius: 50%;
    background-color: white;
}

.m-team-slider-text-bg {
    position: absolute;
    top: 360px;
    width: 100vw;
    height: 182px;
    background-color: white;
    text-align: center;
    padding: 24px 16px;
    box-sizing: border-box;
}

.m-team-slider-text-bg h3 {
    color: #424242;
    margin-bottom: 4px;
    font-size: 1.4em;
}
.m-team-slider-text-bg strong {
    color: #A1A1A1;
    display: block;
    margin-bottom: 12px;
    font-size: 1em;
}
.m-team-slider-text-bg p {
    color: #424242;
    font-size: 1em;
}

.m-team-slider-img {
    position: absolute;
    width: 100vw;
    height: 320px;
    top: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
}

.m-team-slider-img img {
    height: 300px;
}

.m-team-footer {
    position: absolute;
    top: 540px;
    width: 100vw;
    height: 140px;
    background-color: #87A4BB;
    padding-top: 4px;
    box-sizing: border-box;
    overflow: hidden;
}

.m-team-right {
    position: absolute;
    top: 220px;
    right: 10px;
}

.m-team-left {
    position: absolute;
    top: 220px;
    left: 10px;
    transform: rotate(180deg);
}

.m-team-footer button {
    width: 222px;
    min-height: 48px;
    height: 48px;
    background: #3f3f3f;
    border-radius: 6px;
    color: white;
    border: none;
    filter: drop-shadow(0px 2px 1px rgb(55, 55, 55));
    display: block;
    margin: 40px auto 0px auto;
    position: relative;
}

.m-team-bg {
    position: absolute;
    top: -20px;
    left: 0px;
    width: 100vw;
    min-height: 250px;
}