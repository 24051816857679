.container {
  position: relative;
  width: 100vw;
  height: 100vh;
  /* top: -60px; */
  background: linear-gradient(
    270deg,
    rgba(255, 255, 255, 1) 21%,
    rgba(235, 245, 255, 1) 32%,
    rgba(235, 245, 255, 1) 62%,
    rgba(219, 233, 247, 1) 72%
  );
  overflow: hidden;
}

.cloud_1 {
  position: absolute;
  top: 80px;
  left: 100vw;
  animation: right_to_left 16s linear infinite;
  will-change: transform;
}

.cloud_2 {
  position: absolute;
  top: 180px;
  left: 100vw;
  animation: right_to_left 16s linear 4s infinite;
  will-change: transform;
}

.cloud_3 {
  position: absolute;
  top: 160px;
  left: 100vw;
  animation: right_to_left_scaleX 16s linear 8s infinite;
  will-change: transform;
}

.cloud_4 {
  position: absolute;
  top: 230px;
  left: 100vw;
  animation: right_to_left_scaleX 16s linear 12s infinite;
  will-change: transform;
}

.background_layout {
  position: relative;
  width: 100%;
  max-width: 1200px;
  height: 100%;
  margin: 0 auto;
}

.road_container {
  position: absolute;
  left: -330px;
  bottom: 0px;
  width: 1844px;
  height: 456px;
  z-index: 10;
}

.road {
  width: 100%;
  height: 100%;
}

.hand_container {
  position: absolute;
  left: 0px;
  bottom: -180px;
  width: 600px;
  height: 505px;
  z-index: 11;
}

.hand {
  width: 100%;
  height: 100%;
}

.human_container {
  position: absolute;
  left: 885px;
  bottom: 88px;
  width: 59px;
  height: 89px;
  z-index: 12;
}

.human {
  width: 100%;
  height: 100%;
}

.shelves_front {
  position: absolute;
  left: -83px;
  bottom: -227px;
  z-index: 14;
}
.shelves_back {
  position: absolute;
  left: 162px;
  bottom: 150px;
  z-index: 10;
}
.table {
  position: absolute;
  left: 717px;
  bottom: 88px;
  height: 90px;
  z-index: 11;
}

.button {
  position: absolute;
  top: 237px;
  left: 1px;
  width: 249px;
  height: 48px;
  background: var(--primary-orange);
  border-radius: 2px;
  color: white;
  border: none;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  z-index: 21;
  cursor: pointer;
  transition-duration: 250ms;
}

@keyframes right_to_left {
  0% {
    transform: translateX(0px);
  }
  100% {
    transform: translateX(calc(-100vw - 200px));
  }
}

@keyframes right_to_left_scaleX {
  0% {
    transform: scaleX(-1) translateX(0px);
  }
  100% {
    transform: scaleX(-1) translateX(calc(100vw + 200px));
  }
}

@media (max-height: 780px) {
  .background_layout {
    top: -90px;
    /* transform: scale(0.7); */
  }
}
@media (max-height: 670px) {
  .background_layout {
    top: -130px;
    transform: scale(0.9);
  }
}
@media (max-height: 630px) {
  .background_layout {
    top: -140px;
    transform: scale(0.85);
  }
}
@media (max-height: 590px) {
  .background_layout {
    top: -150px;
    transform: scale(0.8);
  }
}

@media (max-height: 550px) {
  .background_layout {
    top: -150px;
    transform: scale(0.7);
  }
}
