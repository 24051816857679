.m-agency-road {
    width: 100vw;
    height: 170px;
    overflow: hidden;
    position: relative;
    background-color: #DAEAF6;
}

.m-agency-road-lines {
    position: absolute;
    top: 70px;
    left: 0;
}

.m-agency-plane {
    position: absolute;
    width: 70vw;
    top: 40px;
    left: 10vw;
    animation: m-plane 4s infinite;
}

@keyframes m-plane {
    0% {transform: translateX(0vw) translateY(0px);}
    50% {transform: translateX(10px) translateY(0px);}
    100% {transform: translateX(0vw) translateY(0px);}
}

.m-agency-clouds-back {
    position: absolute;
    width: 250px;
    top: 40px;
    left: 0vw;
    animation: m-clouds 5s infinite linear;
}
.m-agency-clouds-back-2 {
    position: absolute;
    width: 250px;
    top: 40px;
    left: 100vw;
    animation: m-clouds 5s infinite linear;
}

.m-agency-clouds-front {
    position: absolute;
    width: 100px;
    top: 135px;
    left: 0vw;
    animation: m-clouds 5s infinite linear;
}
.m-agency-clouds-front-2 {
    position: absolute;
    width: 100px;
    top: 135px;
    left: 100vw;
    animation: m-clouds 5s infinite linear;
}

@keyframes m-clouds {
    0% {transform: translateX(0vw);}
    100% {transform: translateX(-100vw);}
}

.m-agency-content {
    padding-top: 48px;
    box-sizing: border-box;
    width: 100vw;
    height: calc(4 * 60vw + 1700px) ;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #63A6AF;
    overflow: hidden;
}

.m-agency-items {    
    position: relative;
    margin-top: 40px;
}

.m-agency-bg-img {
    position: absolute;
    top: 100px;
    left: -50vw;
    width: 200vw;
    height: 1900px;
}