.m-key-item {
    width: 90vw;
    height: 40px;
    background-color: white;
    border-radius: 4px;
    font-size: 1em;
    color: #49525e;
    filter: drop-shadow(0px 2px 1px rgb(113, 113, 113));
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 6px;
}