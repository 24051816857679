.l-project {
  border: 1px solid gray;
  padding: 20px;
  margin-bottom: 24px;
  background-color: white;
}

.l-project-element {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
}

.l-project-element > button {
  background-color: brown;
  color: white;
}

.l-project button {
  cursor: pointer;
}

.l-project > button {
  margin-right: 12px;
}

.a-project-preview-images {
  max-width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  row-gap: 30px;
}

.a-project-preview-images > img {
  width: 45%;
  max-height: 500px;
}

.a-project-preview-image {
  max-width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  row-gap: 30px;
  margin-bottom: 30px;
}

.a-project-preview-image > img {
  width: 100%;
  /* max-height: 500px; */
  margin-bottom: 30px;
}
