.m-customs {
    width: 100vw;
    position: relative;
    background-color: #DAEAF6;
    padding-bottom: 40px;
}

.m-customs-title {
    padding: 40px 0;
}

.m-customs-bg {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 1900px;
}