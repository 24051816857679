.l-text {
    position: relative;
    width: 100%;
}

.l-text > input {
    width: 80%;
    height: 52px;
    margin-bottom: 24px;
    font-size: 16px;
    padding: 12px;
    box-sizing: border-box;
}

.l-text > p {
    width: 100%;
    height: 24px;
    font-size: 16px;
    color: #657486;
    text-align: left;
    padding-left: 12px;
    margin-bottom: 4px;
}