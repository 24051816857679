.l-map-img {
    width: 100%;
    display: block;
    max-width: 1200px;
    margin: 0 auto;
    filter: drop-shadow(0px 0px 1px black);
}

.l-map {
    background-color: white;
    padding: 50px;
    box-sizing: border-box;
    width: 80%;
    border: 1px black solid;
    margin-bottom: 24px;
    cursor: pointer;
}

.l-map--selected {
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 999999999999999;
    left: 0;
    top: 0;
}

.l-map-title {
    width: 100%;
    height: 24px;
    font-size: 16px;
    color: #657486;
    text-align: left;
    padding-left: 12px;
    margin-bottom: 4px;
}

.l-map-edit {
    cursor: default;
    position: relative;
    margin: 100px auto;
    width: 360px;
    height: 520px;
    background-color: white;
    border: 1px solid black;
    display: flex;
    flex-direction: column;
    padding: 25px;
    box-sizing: border-box;
    z-index: 9999999999;
}

.l-map-edit > input {
    height: 46px;
    padding: 12px;
    font-size: 16px;
    margin-bottom: 22px;
}

.l-map-edit > textarea {
    height: 120px;
    padding: 24px 12px;
    font-size: 17px;
    font-family: inherit;
    resize: none;
}

.l-map-edit button {
    margin-top: 22px; 
    height: 62px;
    cursor: pointer;
}

.l-edit-background {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: #ffffffcf;
    z-index: 9999999998;
}

.l-map-close {
    position: fixed;
    z-index: 999999999999;
    top: 20px;
    right: 20px;
    padding: 24px;
    color: white;
    background-color: #657486;
    cursor: pointer;
}