.container {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  z-index: 500;
  /* height: calc(100vh - 60px);
  padding-top: 60px;
  top: -60px; */
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 1) 21%,
    rgba(235, 245, 255, 1) 32%,
    rgba(235, 245, 255, 1) 62%,
    rgba(219, 233, 247, 1) 72%
  );
  overflow: hidden;
}

.cloud_1 {
  position: absolute;
  top: 80px;
  left: 100vw;
  animation: right_to_left 16s linear infinite;
  will-change: transform;
}

.cloud_2 {
  position: absolute;
  top: 180px;
  left: 100vw;
  animation: right_to_left 16s linear 4s infinite;
  will-change: transform;
}

.cloud_3 {
  position: absolute;
  top: 160px;
  left: 100vw;
  animation: right_to_left_scaleX 16s linear 8s infinite;
  will-change: transform;
}

.cloud_4 {
  position: absolute;
  top: 230px;
  left: 100vw;
  animation: right_to_left_scaleX 16s linear 12s infinite;
  will-change: transform;
}

.background_layout {
  position: relative;
  width: 100%;
  max-width: 1200px;
  height: 100%;
  margin: 0 auto;
}

.road_container {
  position: absolute;
  left: -330px;
  bottom: 0px;
  width: 1844px;
  height: 456px;
  z-index: 10;
}

@media (max-height: 900px) {
  .road_container {
    top: 444px;
  }
  .human_container {
    top: 724px;
  }
  .table {
    top: 724px;
  }
  .shelves_back {
    top: 384px;
  }
  .shelves_front {
    top: 144px;
  }
  .hand_container {
    top: 574px;
  }
}
@media (max-height: 800px) {
  .road_container {
    top: 394px;
  }
  .human_container {
    top: 674px;
  }
  .table {
    top: 674px;
  }
  .shelves_back {
    top: 334px;
  }
  .shelves_front {
    top: 94px;
  }
  .hand_container {
    top: 524px;
  }
}
@media (max-height: 700px) {
  .road_container {
    top: 324px;
  }
  .human_container {
    top: 604px;
  }
  .table {
    top: 604px;
  }
  .shelves_back {
    top: 264px;
  }
  .shelves_front {
    top: 20px;
  }
  .hand_container {
    top: 454px;
  }
}

.road {
  width: 100%;
  height: 100%;
}

.hand_container {
  position: absolute;
  left: 0px;
  bottom: -180px;
  width: 600px;
  height: 505px;
  z-index: 11;
}

.hand {
  width: 100%;
  height: 100%;
}

.human_container {
  position: absolute;
  left: 885px;
  bottom: 88px;
  width: 59px;
  height: 89px;
  z-index: 12;
}

.human {
  width: 100%;
  height: 100%;
}

.shelves_front {
  position: absolute;
  left: -83px;
  bottom: -227px;
  z-index: 14;
}
.shelves_back {
  position: absolute;
  left: 162px;
  bottom: 150px;
  z-index: 10;
}
.table {
  position: absolute;
  left: 717px;
  bottom: 88px;
  height: 90px;
  z-index: 11;
}

.button {
  position: absolute;
  top: 242px;
  left: 0px;
  width: 249px;
  height: 48px;
  background: var(--primary-orange);
  border-radius: 2px;
  color: white;
  border: none;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  z-index: 21;
  cursor: pointer;
  transition-duration: 250ms;
}

@keyframes right_to_left {
  0% {
    transform: translateX(0px);
  }
  100% {
    transform: translateX(calc(-100vw - 200px));
  }
}

@keyframes right_to_left_scaleX {
  0% {
    transform: scaleX(-1) translateX(0px);
  }
  100% {
    transform: scaleX(-1) translateX(calc(100vw + 200px));
  }
}
