
.mobile-feedback-form {
    position: relative;
    width: 90vw;
    height: 100vh;
    overflow: hidden;
    z-index: 9999999999999;
  }
  
  .mobile-feedback-input {
    width: 100%;
    height: 60px;
    box-sizing: border-box;
    padding: 12px;
    font-size: 16px;
    border: none;
    box-shadow: inset 0px 1px 6px rgb(195 216 233);
    border-radius: 6px;
    margin-bottom: 18px;
    outline: none;
  }
  .mobile-feedback-textarea {
    width: 100%;
    height: 142px;
    resize: none;
    box-sizing: border-box;
    padding: 12px;
    font-size: 16px;
    border: none;
    box-shadow: inset 0px 1px 6px #CCCCCC;
    border-radius: 6px;
    margin-bottom: 44px;
    outline: none;
    font-family: inherit;
    font-style: normal;
    font-weight: 600;
    line-height: 21px;
  }
  .mobile-feedback-textarea::placeholder, .mobile-feedback-input::placeholder {
    color: #CCCCCC;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    line-height: 21px;
    font-size: 16px;
  }
  .mobile-feedback-button {
    width: 222px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    line-height: 16px;
    color: white;
    border-radius: 4px;
    cursor: pointer;
    background-color: #455158;
    margin: 0 auto;
  }
  
  .mobile-feedback-button:hover {
    transform: scale(1.02);
    background-color: #299d82;
  }
  
  .mobile-feedback-button:active {
    transform: scale(0.98);
  }

  .mobile-feedback-modal {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 99999999999;
  }

  .mobile-feedback-background {
    position: relative;
    width: 100vw;
    height: 100vh;
    background-color: white;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 80px;
    border-radius: 6px;
    box-sizing: border-box;
  }


.mobile-feedback-close {
    position: absolute;
    top: 14px;
    right: 23px;
    cursor: pointer;
    transition-duration: 2000ms;
}

.mobile-feedback-close:hover {
    transform: rotate(720deg);
}

.mobile-feedback-close:active {
    transform: scale(0.98);
}

.mobile-feedback-title {
    font-size: 24px;
    margin-bottom: 36px;
    font-weight: bold;
    color: #49525e;
}

.mobile-feedback-logo {    
    position: relative;
    width: 350px;
    height: 350px;
    background-color: white;
    border-radius: 50%;
}

.mobile-feedback-logo img {    
    position: absolute;
    top: 7px;
    left: 12px;
    width: 90%;
    height: 90%;
}

.mobile-feedback-info {
    display: flex;
    width: 460px;
    height: 600px;
    flex-direction: column;
    align-items: center;
    color: #49525e;
}

.mobile-feedback-inputs {
    display: flex;
    width: 90vw;    
    height: 100vh;
    flex-direction: column;
    align-items: center;
}

.mobile-feedback-info p {
    text-align: center;
    width: 304px;
    font-size: 16px;
}

.mobile-feedback-success-background {
  width: 470px;
  height: 220px;
  background-color: white;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mobile-feedback-success-background h3 {
  margin-top: 40px;
  font-size: 16px;
}
.mobile-feedback-success-background p {
  margin-top: 20px;
  font-size: 16px;
}
.mobile-feedback-success-background button {
  width: 120px;
  height: 40px;
  background-color: #49525e;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 30px;
}

.mobile-feedback-error {
  font-size: 0.8em;
  position: absolute;
  top: 506px;
  right: 40px;
  color: red;
}
.mobile-feedback-error-normal {
  font-size: 0.8em;
  position: absolute;
  top: 576px;
  right: 40px;
  color: red;
}

@media (max-height: 740px) {

  .mobile-feedback-error {
    font-size: 0.8em;
    position: absolute;
    top: 406px;
    right: 40px;
    color: red;
  }
  .mobile-feedback-error-normal {
    font-size: 0.8em;
    position: absolute;
    top: 466px;
    right: 40px;
    color: red;
  }
  
  .mobile-feedback-logo img {
    position: absolute;
    top: 7px;
    left: 54px;
    width: 70%;
    height: 90%;
  }
  .mobile-feedback-modal {
    position: absolute;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 99999999999;
  }
  
  .mobile-feedback-background {
    position: relative;
    width: 100vw;
    height: 100vh;
    background-color: white;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 7vh;
    border-radius: 0px;
    box-sizing: border-box;
  }

  .mobile-feedback-info {
    display: flex;
    width: 460px;
    height: 509px;
    flex-direction: column;
    align-items: center;
    color: #49525e;
  }

  .mobile-feedback-info p {
    text-align: center;
    width: 304px;
    font-size: 16px;
  }

  .mobile-feedback-input {
    height: 42px;
  }

  .mobile-feedback-textarea {
    height: 120px;
    margin-bottom: 30px;
  }

}