body {
  margin: 0;
  font-family: -apple-system, "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 1) 21%,
    rgba(235, 245, 255, 1) 32%,
    rgba(235, 245, 255, 1) 62%,
    rgba(219, 233, 247, 1) 72%
  );
}
button,
input::placeholder,
textarea::placeholder {
  font-family: inherit;
  font-size: 1em;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

input {
  font-family: inherit;
}

input::placeholder {
  font-family: "inherit";
}

input::-moz-placeholder {
  font-family: inherit;
}

input::-webkit-input-placeholder {
  font-family: inherit;
}

:root {
  --header-margin: 60px;
  --background-light-blue: #daeaf9;
  --text-dark: #585858;
  --text-dark-lighten1: #6a6a6a;
  --text-dark-lighten2: #777676;
  --text-dark-lighten3: #8a8a8a;
  --text-dark-lighten4: #b9b9b9;
  --text-bright: white;
  --buttons-dark: #5c5d5e;
  --buttons-dark-darken2: #434546;
  --primary-orange: #ffa169;
}
