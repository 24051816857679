.container {
  position: relative;
  width: 100vw;
  height: calc(100vh - 60px);
  padding-top: 60px;
  /* top: -60px; */
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 1) 21%,
    rgba(235, 245, 255, 1) 32%,
    rgba(235, 245, 255, 1) 62%,
    rgba(219, 233, 247, 1) 72%
  );
  overflow: hidden;
}
.background_layout {
  position: relative;
  width: 100%;
  max-width: 1200px;
  height: 100%;
  margin: 0 auto;
}
.icons {
  position: relative;
  top: -20px;
  width: 970px;
  height: 310px;
  margin: 0 auto;
}

.card {
  position: relative;
  width: 258px;
  height: 280px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  filter: drop-shadow(1px 1px 4px #dbe9f7);
}

.main {
  background-color: white;
  width: 320px;
  height: 80px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-weight: bold;
  line-height: 24px;
}

.subtitle {
  color: #657486;
}

.subtitle_second {
  color: #b3b3b3;
}

.sub {
  background-color: white;
  width: 320px;
  height: 180px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #657486;
  font-size: 16px;
  line-height: 16px;
}

.sub > p {
  width: 260px;
  text-align: center;
  line-height: 20px;
  font-size: 16px;
}

.cards {
  position: relative;
  width: 975px;
  margin: 0 auto;
  height: 230px;
  top: 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

@media (max-height: 800px) {
  .icons {
    top: 20px;
  }
  .cards {
    top: 20px;
  }
}

@media (max-height: 690px) {
  .icons {
    display: none;
  }
  .cards {
    top: 10px;
  }
}

@media (max-height: 540px) {
  .icons {
    display: none;
  }
  .cards {
    top: -20px;
  }
}
