.container {
  position: relative;
  width: 100vw;
  height: calc(100vh - 60px);
  padding-top: 60px;
  top: 0px;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 1) 21%,
    rgba(235, 245, 255, 1) 32%,
    rgba(235, 245, 255, 1) 62%,
    rgba(219, 233, 247, 1) 72%
  );
  overflow: hidden;
}
.background_layout {
  position: relative;
  width: 100%;
  max-width: 1200px;
  height: 100%;
  margin: 0 auto;
}
