.m-geo-item {
  width: 100vw;
  overflow: hidden;
  height: 255px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 24px;
}

.m-geo-item:nth-of-type(odd) {
  height: 175px;
}

.m-geo-item:nth-of-type(odd) .m-geo-bg {
  height: 175px;
}

.m-geo-image {
  position: relative;
  margin-right: 40px;
  box-sizing: border-box;
  padding-top: 24px;
}

.m-geo-text {
  box-sizing: border-box;
  padding-top: 24px;
  position: relative;
  max-width: 180px;
  display: flex;
  flex-direction: column;
}

.m-geo-text h3 {
  position: relative;
  font-size: 1em;
  margin-bottom: 12px;
  color: white;
}

.m-geo-text p {
  position: relative;
  font-size: 1em;
  color: #a7a7a7;
}

.m-geo-bg {
  width: 110vw;
  min-width: 360px;
  position: absolute;
  height: 255px;
  background-color: #455158;
  border-radius: 20px;
}
