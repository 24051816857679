.header-button {
  cursor: pointer;

  width: auto;
  height: 40px;

  background: var(--buttons-dark-darken2);
  border-radius: 6px;
  color: white;

  display: inline-flex;
  justify-content: center;
  align-items: center;

  padding: 0 12px;

  margin-left: 10px;
}

.header-button p {
  margin: 0;
}

.header-button:hover {
  transform: scale(1.02);
  background: var(--primary-orange);
  color: var(--text-bright);
}

.header-button:active {
  transform: scale(0.98);
}

.header-button--active {
  background-color: #fff;
  color: #657486;
}

.header-button--feedback {
  background: var(--buttons-dark-darken2);
  color: #ffffff;
  position: absolute;
  left: 0px;
  margin-left: 0;
}
