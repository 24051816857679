.container {
  position: absolute;
  top: 230px;
  left: 0px;
  z-index: 100;
  padding: 20px;
  background: #ffffff9e;
  border-radius: 8px;
  box-sizing: border-box;
  border: 1px solid #c6d9ed;
}

.service {
  /* transition-duration: 250ms; */
  width: 244px;
  height: 23px;
  cursor: pointer;
  background-color: var(--buttons-dark);
  color: white;
  font-weight: 600;
  padding: 6px;
  border-radius: 3px;
  margin-bottom: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 600;
}
.service:hover {
  background-color: var(--primary-orange);
}
.service:active {
  transform: scale(0.98);
}
.selected {
  /* margin-left: 8px; */
  /* box-sizing: border-box; */
  height: 21px;
  border: 1px solid var(--primary-orange);
  color: var(--primary-orange);
  background: white;
}

.close {
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
  transition-duration: 2000ms;
}

.close:hover {
  transform: rotate(720deg);
}

.close:active {
  transform: scale(0.98);
}

.service:hover.selected {
  background: white;
}

.button {
  margin-top: 16px;
  width: 255px;
  height: 44px;
  background-color: var(--primary-orange);
  border-radius: 4px;
  color: white;
  border: none;
  cursor: pointer;
  transition-duration: 250ms;
}

.modal {
  width: 900px;
  border-radius: 9px;
  height: 414px;
  background-color: white;
  position: absolute;
  top: -1px;
  left: -1px;
  z-index: -1;
  box-sizing: border-box;
  border: 1px solid #c6d9ed;
}
.description {
  position: absolute;
  width: 500px;
  height: 300px;
  top: 77px;
  left: 330px;
  color: var(--text-dark);
}

.modal h3 {
  position: absolute;
  color: #657486;
  width: 500px;
  height: 300px;
  top: 28px;
  left: 330px;
  font-size: 24px;
  font-weight: 800;
}



@media (max-height: 850px) {
  .container {
    z-index: 1000;
    top: 290px;
  }
}

@media (max-height: 790px) {
  .container {
    z-index: 1000;
    top: 330px;
  }
}


@media (max-height: 690px) {
  .container {
    z-index: 1000;
    top: 430px;
  }
}

@media (max-height: 650px) {
  .container {
    z-index: 1000;
    top: 310px;
  }
}

@media (max-height: 550px) {
  .container {
    z-index: 1000;
    top: 280px;
  }
}
