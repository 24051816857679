.m-header-menu {
    position: fixed;
    top: 40px;
    left: 0px;
    width: 100vw;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
}