.title {
  position: absolute;
  width: 1200px;
  top: 63px;
  left: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  color: #62929e;
  height: 70px;
  z-index: 20;
}
.title > p {
  font-weight: 700 !important;
  font-size: 48px;
  text-transform: uppercase;
  filter: drop-shadow(0px 0px 5px white);
}
.dark {
  margin-left: 20px;
  color: var(--buttons-dark);
  filter: drop-shadow(0px 0px 5px white);
}

.description {
  position: absolute;
  width: 1200px;
  top: 140px;
  display: flex;
  flex-direction: column;
  z-index: 20;
  background: white;
  left: -16px;
  width: 430px;
  height: 120px;
  padding: 20px;
  border-radius: 5px;
  filter: drop-shadow(2px 4px 2px #dbe9f7);
  color: var(--text-dark);
}

@media (max-height: 600px) {
  .title {
    top: 20px;
  }
  .description {
    top: 97px;
  }
}
