.container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  /* background-color: #54697a;
  /* background-color: #54697a; */
}

.a {
  position: absolute;
  transition-timing-function: ease-in-out;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
}

.b {
  position: absolute;
  transition-timing-function: ease-in-out;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
}
