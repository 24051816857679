.a-main-inputs {
    margin: 40px auto;
    width: 80%;
    display: flex;
    flex-direction: column;
}

.a-main-textarea {
    width: 80%;
    height: 120px;
    resize: none;
    margin-bottom: 24px;
    font-size: 19px;
    padding: 12px;
    box-sizing: border-box;
}

.a-main-input {
    width: 80%;
    height: 52px;
    margin-bottom: 24px;
    font-size: 16px;
    padding: 12px;
    box-sizing: border-box;
}

.a-main-title {
    margin-bottom: 8px;
    margin-left: 12px;
}

.a-main-save-button-container {
    display: flex;
    align-items: center;
    margin-top: 20px;

}

.a-main-save-button {
    margin-right: 24px;
    width: 220px;
    height: 52px;
    color: white;
    background-color: rgb(18, 155, 59);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition-duration: 300ms;
}

.a-main-save-button:active {
    transform: scale(0.95);
}

.a-main-error {
    color: rgb(129, 43, 43);
    transition-duration: 500ms;

}

.a-main-success {
    color: rgb(43, 129, 69);
    transition-duration: 500ms;

}

.a-main-warning {
    color: rgb(129, 128, 43);
    transition-duration: 500ms;

}