.container {
  position: relative;
  width: 100vw;
  height: calc(100vh - 60px);
  padding-top: 60px;
  /* top: -60px; */
  background: linear-gradient(
    270deg,
    rgba(255, 255, 255, 1) 21%,
    rgba(235, 245, 255, 1) 32%,
    rgba(235, 245, 255, 1) 62%,
    rgba(219, 233, 247, 1) 72%
  );
  overflow: hidden;
}

.map {
  position: absolute;
  top: 90px;
  left: 170px;
  width: 1200px;
  height: 800px;
}

.map_text {
  position: absolute;
  top: 390px;
  left: 1037px;
  font-size: 16px;
  width: 120px;
  height: 67px;
  padding: 12px 20px;
  color: var(--text-dark-lighten2);
  background: white;
  border: 1px solid #6fbac4;
  line-height: 22px;
}

.background_layout {
  position: relative;
  width: 100%;
  max-width: 1200px;
  height: 100%;
  margin: 0 auto;
}

.items {
  position: absolute;
  top: 250px;
  width: 580px;
  height: 450px;
  padding: 20px;
  background: white;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  filter: drop-shadow(1px 1px 4px #dbe9f7);
}
.item {
  width: 500px;
  height: 112px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.icon {
  margin-right: 24px;
  filter: drop-shadow(0px 0px 1px #a3a3a3);
}
.description {
  color: var(--text-dark-lighten2);
}

@media (max-height: 810px) {
  .items {
    top: 200px;
  }
  .map {
    top: 40px;
  }
  .map_text {
    top: 340px;
  }
}

@media (max-height: 760px) {
  .items {
    top: 170px;
  }
  .map {
    top: 20px;
  }
  .map_text {
    top: 320px;
  }
}
@media (max-height: 730px) {
  .items {
    top: 150px;
  }
  .map {
    top: 20px;
  }
  .map_text {
    top: 320px;
  }
}
@media (max-height: 710px) {
  .items {
    top: 170px;
    height: 350px;
  }
  .map {
    top: 0px;
  }
  .map_text {
    top: 300px;
  }
}
@media (max-height: 630px) {
  .items {
    top: 140px;
    height: 350px;
  }
  .map {
    top: -50px;
  }
  .map_text {
    top: 250px;
  }
}
@media (max-height: 610px) {
  .items {
    top: 140px;
    height: 300px;
  }
  .map {
    top: -70px;
  }
  .map_text {
    top: 230px;
  }
}
@media (max-height: 550px) {
  .items {
    top: 110px;
    left: -30px;
    height: 300px;
    transform: scale(0.9);
  }
  .map {
    top: -90px;
  }
  .map_text {
    top: 210px;
  }
}
