.m-contacts {
    width: 100vw;
    /* height: 787px; */
    background-color: #87A4BB;
    /* padding-bottom: 100px; */
    position: relative;
}

.m-contacts-title {
    padding: 20px 0;
}

.m-contacts-logo {
    width: 100%;
    height: 80vw;
    position: relative;
    margin-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #1f1f1f;
    padding: 40px 0;
}

.m-contacts-logo img {
    display: block;
    width: 80vw;
    height: 80vw;
    padding-bottom: 50px;
    box-sizing: border-box;
}

.m-contacts-info {
    margin: 0 auto;
    width: 90vw;
    max-width: 440px;
    /* height: 245px; */
    text-align: center;
    padding: 26px 40px 40px 40px;
    box-sizing: border-box;
    background-color: white;
    border-radius: 6px;
    color: #49525e;
    margin-bottom: 40px;
    filter: drop-shadow(0px 0px 11px rgba(0, 0, 0, 0.1));
}

.m-contacts-map {
  filter: drop-shadow(0px 0px 11px rgba(0, 0, 0, 0.1));
  margin-bottom: 32px;
  width: 100vw;
  position: relative;
  /* left: calc((100vw - 600px)*0.5); */
  height: 238px;
  overflow: hidden;
}

.m-contacts-map > img {
  position: absolute;
}

.m-contacts-footer button {
    width: 222px;
    min-height: 48px;
    height: 48px;
    background: #3f3f3f;
    border-radius: 6px;
    color: white;
    border: none;
    filter: drop-shadow(0px 2px 1px rgb(55, 55, 55));
    display: block;
    margin: 40px auto 0px auto;
    position: relative;
}

.m-contacts-bg {    
    position: absolute;
    top: -100px;
    left: 0;
    width: 100vw;
}