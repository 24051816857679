.m-project-form {
  position: relative;
  width: 100vw;
  height: 100vh;
  z-index: 10000000;
}

.m-project-input {
  width: 100%;
  height: 60px;
  box-sizing: border-box;
  padding: 12px;
  font-size: 16px;
  border: none;
  box-shadow: inset 0px 1px 6px rgb(195 216 233);
  border-radius: 6px;
  margin-bottom: 18px;
  outline: none;
}
.m-project-textarea {
  width: 100%;
  height: 175px;
  resize: none;
  box-sizing: border-box;
  padding: 12px;
  font-size: 16px;
  border: none;
  box-shadow: inset 0px 1px 6px rgb(195 216 233);
  border-radius: 6px;
  margin-bottom: 52px;
  outline: none;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
}
.m-project-textarea::placeholder,
.m-project-input::placeholder {
  color: #c6d7e4;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  font-size: 16px;
}
.m-project-button {
  width: 222px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  line-height: 16px;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  background-color: #455158;
  margin: 0 auto;
}

.m-project-button:hover {
  transform: scale(1.02);
  background-color: #299d82;
}

.m-project-button:active {
  transform: scale(0.98);
}

.m-project-modal {
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 99999999999;
}

.m-project-background {
  position: relative;
  width: 100vw;
  height: 100vh;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
}

.m-project-close {
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
  transition-duration: 2000ms;
  height: 20px;
  width: 20px;
}

.m-project-close:hover {
  transform: rotate(720deg);
}

.m-project-close:active {
  transform: scale(0.98);
}

.m-project-title {
  font-size: 24px;
  margin-bottom: 32px;
  font-weight: bold;
  color: #49525e;
  padding: 40px 0 0 0;
}

.m-project-text {
  height: 90vh;
  padding-bottom: 100px;
  width: 90vw;
  box-sizing: border-box;
  overflow-y: scroll;
  color: #49525e;
}

.m-project-text::-webkit-scrollbar {
  display: none;
}

.m-project-text p {
  margin-bottom: 32px;
  text-indent: 40px;
}

@media (max-height: 740px) {
  .m-project-modal {
    position: absolute;
    width: 100vw;
    height: calc(100vh + 6px);
    top: -6px;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    padding-top: 0px;
    align-items: center;
    z-index: 99999999999;
  }

  .m-project-background {
    position: relative;
    width: 100vw;
    height: 110vh;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
  }
}

.m-project-prod-image > img {
  max-width: 90vw;
  min-width: 90vw;
}
