.l-image-input {
}

.l-image-input > * {
    width: 453px;
    min-height: 60px;
    background-color: white;
    border: 1px black solid;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.l-image-label-p {
    display: block;
}

.l-image-input img {
    max-width: 453px;
}