.m-division-road {
    width: 100vw;
    height: 170px;
    overflow: hidden;
    position: relative;
    background-color: #54697A;
}

.m-division-road-lines {
    position: absolute;
    top: 70px;
    left: 0;
}

.m-division-car {
    position: absolute;
    top: 40px;
    left: -34px;
}

.m-division-content {
    padding-top: 48px;
    box-sizing: border-box;
    width: 100vw;
    height: 1120px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #DAEAF6;
    overflow: hidden;
}

.m-division-items {    
    margin-top: 40px;
}

.m-division-bg-img {    
    position: absolute;
    top: 50px;
    left: 0;
    width: 100vw;
}