.m-geo-road {
    width: 100vw;
    height: 170px;
    overflow: hidden;
    position: relative;
    background-color: #54697A;
}

.m-geo-road-lines {
    width: 200vw;
    position: absolute;
    top: 50px;
    left: 0;
    /* transform: translateX(0px) */
    animation: road-lines 1s infinite linear;
}

@keyframes road-lines {
    0% {transform: translateX(0px);}
    100% {transform: translateX(-48vw);}
}

.m-geo-car {
    position: absolute;
    top: 10px;
    left: -34px;
    animation: car 10s infinite;
}

@keyframes car {
    0% {transform: translateX(0vw);}
    50% {transform: translateX(10vw);}
    100% {transform: translateX(0vw);}
}

.m-geo-content {
    padding-top: 48px;
    box-sizing: border-box;
    width: 100vw;
    height: 1200px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #DAEAF6;
    overflow: hidden;
}

.m-geo-items {    
    margin-top: 40px;
}

.m-geo-bg-img {    
    position: absolute;
    top: 0;
    left: -30vw;
    width: 130vw;
    min-height: 1500px
}