.m-agency-item {
    width: 100vw;
    overflow: hidden;
    height: 285px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 44px;
}

.m-agency-image {
    position: relative;
    margin-right: 40px;
    box-sizing: border-box;
    padding-top: 24px;
}

.m-agency-text {
    box-sizing: border-box;
    padding-top: 24px;
    position: relative;
    max-width: 250px;
    display: flex;
    flex-direction: column;
}

.m-agency-text h3 {
    position: relative;
    font-size: 1.4em;
    color: white;
}

.m-agency-text p {
    position: relative;
    font-size: 1em;
    color: white;
}

.m-agency-bg {
    width: 110vw;
    min-width: 360px;
    position: absolute;
    height: 285px;
    background-color: #455158;
    border-radius: 20px;
}

.m-agency-subtitle {
    color: #A7A7A7;
    margin-bottom: 12px;
}

.m-agency-icon {
    display: block;
    width: 60vw;
    height: 60vw;
    margin: 20px auto 40px auto;
}