.title {
  position: absolute;
  width: 1200px;
  top: 63px;
  left: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  color: #62929e;
  height: 70px;
  z-index: 20;
}
.title > p {
  font-weight: 700 !important;
  font-size: 48px;
  text-transform: uppercase;
}
.dark {
  margin-left: 20px;
  color: #62929e;
}
.subtitle {
  position: absolute;
  font-size: 46px;
  color: var(--text-dark-lighten2);
  top: 122px;
}
