.container {
  position: relative;
  width: 100vw;
  height: calc(100vh - 60px);
  padding-top: 60px;
  /* top: -60px; */
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 1) 21%,
    rgba(235, 245, 255, 1) 32%,
    rgba(235, 245, 255, 1) 62%,
    rgba(219, 233, 247, 1) 72%
  );
  overflow: hidden;
}

.map {
  position: absolute;
  top: 50px;
  left: 300px;
  width: 1200px;
  height: 800px;
}

.map_text {
  position: absolute;
  top: 560px;
  left: 969px;
  font-size: 16px;
  width: 190px;
  height: 111px;
  padding: 12px 20px;
  color: var(--text-dark-lighten2);
  background: white;
  border: 1px solid #6fbac4;
  line-height: 22px;
}

.address_text {
}

.address_description {
  color: var(--text-dark-lighten4);
}

.background_layout {
  position: relative;
  width: 100%;
  max-width: 1200px;
  height: 100%;
  margin: 0 auto;
}

.items {
  position: absolute;
  top: 250px;
  width: 580px;
  height: 450px;
  padding: 20px;
  background: white;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  filter: drop-shadow(1px 1px 4px #dbe9f7);
}
.item {
  width: 500px;
  height: 112px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.icon {
  filter: drop-shadow(0px 0px 1px #a3a3a3);
  margin-right: 24px;
}
.description {
  color: var(--text-dark-lighten2);
}

@media (max-height: 810px) {
  .items {
    top: 200px;
  }
  .map {
    top: 40px;
  }
  .map_text {
    top: 550px;
  }
}

@media (max-height: 760px) {
  .items {
    top: 170px;
  }
  .map {
    top: 20px;
  }
  .map_text {
    top: 530px;
  }
}
@media (max-height: 730px) {
  .items {
    top: 150px;
  }
  .map {
    top: -20px;
  }
  .map_text {
    top: 490px;
  }
}
@media (max-height: 710px) {
  .items {
    top: 170px;
    height: 350px;
  }
  .map {
    top: -80px;
  }
  .map_text {
    top: 430px;
  }
}
@media (max-height: 630px) {
  .items {
    top: 140px;
    height: 350px;
  }
  .map {
    top: -100px;
  }
  .map_text {
    top: 410px;
  }
}
@media (max-height: 610px) {
  .items {
    top: 140px;
    height: 300px;
  }
  .map {
    top: -160px;
  }
  .map_text {
    top: 350px;
  }
}
@media (max-height: 550px) {
  .items {
    top: 110px;
    left: -30px;
    height: 300px;
    transform: scale(0.9);
  }
  .map {
    top: -230px;
  }
  .map_text {
    top: 290px;
  }
}
