.a-images-image-container {
  width: 100%;
  height: 240px;
  overflow: hidden;
  background: white;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 1em;
  padding: 1em;
}

.a-images-image-actions {
  display: flex;
  flex-direction: column;
  width: 30%;
  border: 1px solid lightgray;
  padding: 12px;
}

.a-images-image-img-container {
  /* width: 45%; */
  position: relative;
  width: 65%;
  height: 200px;
  margin-right: 5%;
  padding: 20px;
  border: 1px solid lightgray;
  padding: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.a-images-image-img {
  max-width: 100%;
  max-height: 100%;
}
.a-images-image-actions > input {
  width: 100%;
  min-height: 60px;
  box-sizing: border-box;
  margin-bottom: 1em;
  padding: 12px;
}
.a-images-image-button {
  width: 100%;
  cursor: pointer;
  margin-bottom: 1em;
}

.a-images-image-error-button {
  width: 100%;
  cursor: pointer;
  background: rgb(208, 76, 76);
  color: white;
}

.a-images-upload {
  width: 100%;
  background: white;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 30px;
  margin-bottom: 30px;
  box-sizing: border-box;
}

.a-images-upload-button {
  margin-top: 20px;
  width: 50%;
}
