.container {
  position: relative;
  width: 100vw;
  height: calc(100vh - 60px);
  padding-top: 60px;
  /* top: -60px; */
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 1) 21%,
    rgba(235, 245, 255, 1) 32%,
    rgba(235, 245, 255, 1) 62%,
    #dbe9f7 72%
  );
  overflow: hidden;
}

.map {
  position: absolute;
  width: 1460px;
  z-index: 20;
  filter: drop-shadow(1px 1px 2px rgb(159, 186, 199));
}

.map_auto {
  right: -190px;
  top: -50px;
}

.map_train {
  right: -199px;
  top: -42px;
}

.map_fly {
  right: -198px;
  top: -49px;
}

.map_ship {
  right: -194px;
  top: -60px;
}

.map_main {
  right: -190px;
  top: -50px;
}

.cloud_1 {
  position: absolute;
  bottom: 160px;
  right: 100vw;
  animation: left_to_right 32s linear infinite;
  will-change: transform;
}

.cloud_2 {
  position: absolute;
  bottom: 180px;
  right: 100vw;
  animation: left_to_right 32s linear 8s infinite;
  will-change: transform;
}

.cloud_3 {
  position: absolute;
  bottom: 190px;
  right: 100vw;
  animation: left_to_right_scaleX 32s linear 16s infinite;
  will-change: transform;
}

.cloud_4 {
  position: absolute;
  bottom: 220px;
  right: 100vw;
  animation: left_to_right_scaleX 32s linear 24s infinite;
  will-change: transform;
}

.background_layout {
  position: relative;
  width: 100%;
  max-width: 1200px;
  height: 100%;
  margin: 0 auto;
}

.train_container {
  position: absolute;
  right: -50px;
  bottom: 60px;
  width: 639px;
  height: 85px;
  animation: train_income 5s ease-in-out;
  will-change: transform;
  z-index: 3;
}

.train {
  width: 100%;
  height: 100%;
  animation: train_loop 30s ease-in-out infinite;
  will-change: transform;
  z-index: 3;
}

.train_environment {
  position: absolute;
  bottom: 0px;
  width: 100vw;
  height: 66px;
  z-index: 3;
}
.train_ground {
  width: 100%;
  height: 100%;
  background: white;
}
.train_bottom {
  position: absolute;
  top: 6px;
  width: 100%;
  height: 10px;
  background: #dfdfdf;
}
.train_top {
  position: absolute;
  top: 0px;
  width: 100%;
  height: 6px;
  background: #89929a;
}
.tree_1 {
  position: absolute;
  bottom: 50px;
  left: -100px;
  animation: left_to_right 44s linear infinite;
  will-change: transform;
  z-index: 4;
}

.tree_2 {
  position: absolute;
  bottom: 50px;
  left: -100px;
  animation: left_to_right 44s linear 16s infinite;
  will-change: transform;
  z-index: 4;
}

.tree_3 {
  position: absolute;
  bottom: 55px;
  left: -100px;
  animation: left_to_right 44s linear 8s infinite;
  will-change: transform;
  z-index: 2;
}

.tree_4 {
  position: absolute;
  bottom: 55px;
  left: -100px;
  animation: left_to_right 44s linear 32s infinite;
  will-change: transform;
  z-index: 2;
}
@keyframes left_to_right {
  0% {
    transform: translateX(-250px);
  }
  100% {
    transform: translateX(calc(100vw + 300px));
  }
}
@keyframes left_to_right_scaleX {
  0% {
    transform: scaleX(-1) translateX(250px);
  }
  100% {
    transform: scaleX(-1) translateX(calc(-100vw - 300px));
  }
}

@keyframes train_income {
  0% {
    transform: translateX(500px);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes train_loop {
  0% {
    transform: scaleX(-1) translateX(0);
  }
  50% {
    transform: scaleX(-1) translateX(-100px);
  }
  100% {
    transform: scaleX(-1) translateX(0);
  }
}

.shelves_front {
  position: absolute;
  left: -83px;
  bottom: -227px;
  z-index: 14;
}
.shelves_back {
  position: absolute;
  left: 162px;
  bottom: 150px;
  z-index: 10;
}
.table {
  position: absolute;
  left: 717px;
  bottom: 88px;
  height: 90px;
  z-index: 11;
}

.button {
  position: absolute;
  top: 237px;
  left: 1px;
  width: 249px;
  height: 48px;
  background: var(--primary-orange);
  border-radius: 2px;
  color: white;
  border: none;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  z-index: 21;
  cursor: pointer;
  transition-duration: 250ms;
}

@keyframes right_to_left {
  0% {
    transform: translateX(0px);
  }
  100% {
    transform: translateX(calc(-100vw - 200px));
  }
}

@keyframes right_to_left_scaleX {
  0% {
    transform: scaleX(-1) translateX(0px);
  }
  100% {
    transform: scaleX(-1) translateX(calc(100vw + 200px));
  }
}

.car_container {
  position: absolute;
  right: 501px;
  bottom: 0px;
  width: 361px;
  height: 121px;
  animation: car_income 6s ease-in-out;
  will-change: transform;
  z-index: 7;
}

.car {
  width: 100%;
  height: 100%;
  animation: car_loop 14s ease-in-out infinite;
  -moz-animation: car_loop 14s ease-in-out infinite;
  will-change: transform;
}

.car_environment {
  position: absolute;
  bottom: 0px;
  width: 100vw;
  height: 50px;
  z-index: 6;
}
.car_ground {
  position: absolute;
  width: 100%;
  height: 50px;
  background: #616161;
}

@keyframes car_income {
  0% {
    transform: translateX(820px);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes car_loop {
  0% {
    transform: scale(0.7) translateX(0);
  }
  50% {
    transform: scale(0.7) translateX(-50px);
  }
  100% {
    transform: scale(0.7) translateX(0);
  }
}

.plane {
  position: absolute;
  width: 80px;
  bottom: 200px;
  right: 100vw;
  animation: right_to_left 50s linear infinite;
  -moz-animation: right_to_left 50s linear infinite;
  /* will-change: transform; */
  z-index: 3;
}
@keyframes right_to_left {
  0% {
    transform: translateX(0px);
  }
  100% {
    transform: translateX(calc(100vw + 200px));
  }
}

.ship_container {
  position: absolute;
  bottom: 80px;
  right: 100vw;
  /* animation: ship_income 5s linear 1;
  -moz-animation: ship_income 5s linear 1;
  will-change: transform; */
  z-index: 2;
}

.ship {
  width: 140px;
  animation: ship_loop 180s linear infinite;
}

@keyframes ship_loop {
  0% {
    transform: translateX(0px);
  }
  /* 50% {
    transform: translateX(80px);
  } */
  100% {
    transform: translateX(calc(100vw + 200px));
  }
}
/* 
@keyframes ship_income {
  0% {
    transform: translateX(500px);
  }
  100% {
    transform: translateX(0);
  }
} */

.ship_environment {
  position: absolute;
  bottom: 0px;
  width: 100vw;
  height: 100px;
  z-index: 1;
}
.ship_sea {
  width: 100%;
  height: 100%;
  background: #89b7c3;
}
@media (max-height: 900px) {
  .map {
    position: absolute;
    width: 1460px;
    z-index: 20;
    filter: drop-shadow(1px 1px 2px rgb(159, 186, 199));
  }

  .map_auto {
    right: -190px;
    top: -110px;
  }

  .map_train {
    right: -199px;
    top: -102px;
  }

  .map_fly {
    right: -198px;
    top: -109px;
  }

  .map_ship {
    right: -194px;
    top: -120px;
  }

  .map_main {
    right: -190px;
    top: -110px;
  }
}

@media (max-height: 800px) {
  .map {
    position: absolute;
    width: 1460px;
    z-index: 20;
    filter: drop-shadow(1px 1px 2px rgb(159, 186, 199));
  }

  .map_auto {
    right: -190px;
    top: -130px;
  }

  .map_train {
    right: -199px;
    top: -122px;
  }

  .map_fly {
    right: -198px;
    top: -129px;
  }

  .map_ship {
    right: -194px;
    top: -140px;
  }

  .map_main {
    right: -190px;
    top: -130px;
  }
}

@media (max-height: 700px) {
  .map {
    position: absolute;
    width: 1100px;
    z-index: 20;
    filter: drop-shadow(1px 1px 2px rgb(159, 186, 199));
  }

  .map_auto {
    right: -120px;
    top: -100px;
  }

  .map_train {
    right: -89px;
    top: -92px;
  }

  .map_fly {
    right: -88px;
    top: -99px;
  }

  .map_ship {
    right: -84px;
    top: -110px;
  }

  .map_main {
    right: -80px;
    top: -100px;
  }
}

@media (max-height: 600px) {
  .map {
    position: absolute;
    width: 900px;
    z-index: 20;
    filter: drop-shadow(1px 1px 2px rgb(159, 186, 199));
  }

  .map_auto {
    right: -20px;
    top: -100px;
  }

  .map_train {
    right: 11px;
    top: -92px;
  }

  .map_fly {
    right: 12px;
    top: -99px;
  }

  .map_ship {
    right: 16px;
    top: -110px;
  }

  .map_main {
    right: 20px;
    top: -100px;
  }
}
