.projects {
  padding-top: 10px;
  padding-left: 40px;
  position: absolute;
  left: -40px;
  width: 1220px;
  height: calc(100vh - 200px);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 16px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.transparent {
  mask-image: linear-gradient(180deg, #ffffff 0%, #000000 50%, transparent);
  mask-mode: alpha;
}

/* width */
.projects::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.projects::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.projects::-webkit-scrollbar-thumb {
  background: #c7d5e4;
  border-radius: 6px;
}

/* Handle on hover */
.projects::-webkit-scrollbar-thumb:hover {
  background: var(--primary-orange);
}
.project:nth-child(odd) {
  margin-right: 0px;
}

.project {
  width: 590px;
  height: 120px;
  box-sizing: border-box;
  background: white;
  filter: drop-shadow(1px 1px 4px #dbe9f7);
  color: var(--text-bright);
  border-radius: 6px;
  padding: 0 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition-duration: 400ms;
}

.project:hover {
  transform: scale(1.03);
}

.title {
  font-size: 24px;
  margin-bottom: 12px;
  color: var(--text-dark-lighten3);
}

.open {
  width: 120px;
  height: 28px;
  /* color: var(--primary-orange); */
  color: #62929e;
  /* border: 2px solid var(--primary-orange); */
  border: none;
  background: white;
  outline: none;
  border-radius: 4px;
  align-self: end;
  cursor: pointer;
}

.open:hover {
  background: #62929e;
  color: var(--text-bright);
  border: none;
}

.show_container {
  position: fixed;
  width: 100vw;
  height: 300px;
  background: #ffffffad;
  bottom: 0;
  left: 0;
}
.show {
  position: absolute;
  left: 490px;
  bottom: 20px;
  width: 220px;
  height: 48px;
  color: var(--text-bright);
  background: var(--primary-orange);
  margin: 40px auto;
  outline: none;
  border: none;
  border-radius: 4px;
  align-self: end;
  cursor: pointer;
}

.selected_container {
  position: absolute;
  width: 1200px;
  height: 100vh;
  left: 0px;
  top: 0px;
  z-index: 100;
}

.selected {
  width: 1200px;
  height: calc(100vh - 60px);
  top: 0px;
  left: 0;
  position: absolute;
  z-index: 40;
  border-radius: 6px;
  filter: drop-shadow(1px 1px 4px #dbe9f7);
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 50px 40px 80px 40px;
  box-sizing: border-box;
  color: var(--text-dark);
}

.selected_title {
  font-size: 48px;
  margin-bottom: 32px;
  text-align: center;
}

.selected > p {
  margin-bottom: 24px;
  background: white;
  padding: 12px;
  border-radius: 6px;
}

/* width */
.selected::-webkit-scrollbar {
  width: 10px;
  border-radius: 0 6px 6px 0;
  overflow: hidden;
}

/* Track */
.selected::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 0 6px 6px 0;
}

/* Handle */
.selected::-webkit-scrollbar-thumb {
  background: #c7d5e4;
  border-radius: 6px;
}

/* Handle on hover */
.selected::-webkit-scrollbar-thumb:hover {
  background: var(--primary-orange);
}

.close {
  position: absolute;
  top: 30px;
  right: -60px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: none;
  background: white;
  cursor: pointer;
  transition-duration: 2000ms;
  transition-property: transform;
  z-index: 401;
}

@media (max-width: 1320px) {
  .close {
    top: 40px;
    right: 30px;
    filter: drop-shadow(2px 2px 2px #dbe9f7);
  }
}

.close:focus {
  border: 2px solid var(--primary-orange);
  outline: var(--primary-orange);
}

.close:hover {
  transform: rotate(720deg);
}

.close:active {
  transform: scale(0.98);
}

.image {
  filter: saturate(0);
  transition-duration: 250ms;
  transition-property: filter;
}

.image:hover {
  filter: none;
}
