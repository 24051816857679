.header-bg {
  width: 100vw;
  height: 60px;
  position: relative;
  left: 0;
  top: 0;

  transition-duration: 700ms;
  transition-property: background;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 1) 21%,
    rgba(235, 245, 255, 1) 32%,
    rgba(235, 245, 255, 1) 62%,
    rgba(219, 233, 247, 1) 72%
  );
  /* background-color: #54697a; */
  /* background: #adc4d7; */
  z-index: 100000;
}

.header {
  width: 100vw;
  height: 60px;
  position: relative;
  left: 0;
  top: 0;

  transition-duration: 700ms;
  transition-property: background;
  opacity: 1;
  /* background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 1) 21%,
    rgba(235, 245, 255, 1) 32%,
    rgba(235, 245, 255, 1) 62%,
    rgba(219, 233, 247, 1) 72%
  ); */
  /* background-color: #54697a; */
  /* background: #adc4d7; */
  z-index: 100000;
}

.header-layout {
  position: relative;
  margin: 0 auto;
  top: 12px;
  height: 36px;
  width: 1200px;
  z-index: 100000000;
}

.header-icon {
  transition-duration: 250ms;
  position: absolute;
  top: -2px;
  right: 20px;
  z-index: 999999;
  width: 40px;
  height: 40px;
  filter: drop-shadow(0px 0px 1px #3a3a3a);
}

.header-icon:hover {
  transform: scale(1.1);
  cursor: pointer;
}

.header-icon:active {
  transform: scale(0.95);
}

.header-hide-icon {
  transition-duration: 250ms;
  z-index: 999999;
  width: 40px;
  height: 40px;
  margin-left: 12px;
  margin-right: 20px;
  filter: drop-shadow(0px 0px 1px #3a3a3a);
}

.header-hide-icon:hover {
  transform: scale(1.1);
  cursor: pointer;
}

.header-hide-icon:active {
  transform: scale(0.95);
}

.header-buttons {
  position: absolute;
  width: 1200px;
  height: 36px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
