body {
  margin: 0;
  overflow: hidden;

  background-color: var(--background-light-blue);
  /* background-image: url('/public/background.svg'); */
}

* {
  font-weight: 600;
  margin: 0;
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.App {
  width: 100vw;
  height: 100vh;
  max-width: 100vw;
  max-height: 100vh;
  overflow: hidden;
  font-weight: 600;
}

.slider {
  width: 100vw;
  height: 100vh;
}

.blue {
  color: white;
  background-color: rgb(27, 27, 136);
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.red {
  color: white;
  background-color: rgb(136, 27, 49);
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.lama-slider-item {
  width: 100vw;
  height: 100vh;
  /* background-color: rgb(25, 20, 47); */
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}
