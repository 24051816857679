.feedback-form {
  position: relative;
  width: 460px;
  height: 505px;
  z-index: 9999999999999;
}

.feedback-input {
  width: 100%;
  height: 60px;
  box-sizing: border-box;
  padding: 12px;
  font-size: 16px;
  border: none;
  box-shadow: inset 0px 1px 6px rgb(195 216 233);
  border-radius: 6px;
  margin-bottom: 18px;
  outline: none;
}
.feedback-textarea {
  width: 100%;
  height: 142px;
  resize: none;
  box-sizing: border-box;
  padding: 12px;
  font-size: 16px;
  border: none;
  box-shadow: inset 0px 1px 6px #cccccc;
  border-radius: 6px;
  margin-bottom: 44px;
  outline: none;
  font-family: inherit;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
}
.feedback-textarea::placeholder,
.feedback-input::placeholder {
  color: #cccccc;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  font-size: 16px;
}
.feedback-button {
  width: 222px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  line-height: 16px;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  background-color: #455158;
  margin: 0 auto;
}

.feedback-button:hover {
  transform: scale(1.02);
  background-color: #299d82;
}

.feedback-button:active {
  transform: scale(0.98);
}

.feedback-modal {
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: -120px;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  flex-direction: column;
  padding-top: 160px;
  align-items: center;
  z-index: 99999999999;
}

.feedback-background {
  position: relative;
  width: 1200px;
  height: 780px;
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 80px;
  border-radius: 6px;
  box-sizing: border-box;
}

.feedback-close {
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
  transition-duration: 2000ms;
}

.feedback-close:hover {
  transform: rotate(720deg);
}

.feedback-close:active {
  transform: scale(0.98);
}

.feedback-title {
  font-size: 24px;
  margin-bottom: 36px;
  font-weight: bold;
  color: #657486;
}

.feedback-logo {
  position: relative;
  width: 350px;
  height: 350px;
  background-color: white;
  border-radius: 50%;
}

.feedback-logo img {
  position: absolute;
  top: 7px;
  left: 12px;
  width: 90%;
  height: 90%;
}

.feedback-info {
  display: flex;
  width: 460px;
  height: 600px;
  flex-direction: column;
  align-items: center;
  color: #657486;
}

.feedback-inputs {
  display: flex;
  width: 570px;
  height: 600px;
  flex-direction: column;
  align-items: center;
}

.feedback-info p {
  text-align: center;
  width: 304px;
  font-size: 16px;
}

.feedback-success-background {
  width: 470px;
  height: 220px;
  background-color: white;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.feedback-success-background h3 {
  margin-top: 40px;
  font-size: 16px;
}
.feedback-success-background p {
  margin-top: 20px;
  font-size: 16px;
}
.feedback-success-background button {
  width: 120px;
  height: 40px;
  background-color: #657486;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 30px;
}

.feedback-error {
  position: absolute;
  top: 506px;
  right: 40px;
  color: red;
}
.feedback-error-normal {
  position: absolute;
  top: 576px;
  right: 40px;
  color: red;
}

@media (max-height: 740px) {
  .feedback-error {
    position: absolute;
    top: 406px;
    right: 40px;
    color: red;
  }
  .feedback-error-normal {
    position: absolute;
    top: 466px;
    right: 40px;
    color: red;
  }

  .feedback-logo img {
    position: absolute;
    top: 7px;
    left: 54px;
    width: 70%;
    height: 90%;
  }
  .feedback-modal {
    position: absolute;
    width: 100vw;
    height: 100vh;
    top: -160px;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    flex-direction: column;
    padding-top: 160px;
    align-items: center;
    z-index: 99999999999;
  }

  .feedback-background {
    position: relative;
    width: 1140px;
    height: 780px;
    background-color: white;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 7vh;
    border-radius: 0px;
    box-sizing: border-box;
  }

  .feedback-info {
    display: flex;
    width: 460px;
    height: 509px;
    flex-direction: column;
    align-items: center;
    color: #657486;
  }

  .feedback-info p {
    text-align: center;
    width: 304px;
    font-size: 16px;
  }

  .feedback-input {
    height: 42px;
  }

  .feedback-textarea {
    height: 30vh;
    margin-bottom: 30px;
  }
}

@media (max-height: 640px) {
  .feedback-textarea {
    height: 25vh;
  }

  .feedback-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 45vh;
    height: 45vh;
  }

  .feedback-logo > img {
    position: relative;
    left: 0;
    top: 0;
  }
}

@media (max-height: 580px) {
  .feedback-textarea {
    height: 15vh;
  }
}

@media (max-height: 540px) {
  .feedback-textarea {
    height: 10vh;
  }
}
