.m-division-item {
    width: 100vw;
    overflow: hidden;
    height: 200px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 24px;
}

.m-division-image {
    position: relative;
    margin-right: 40px;
    box-sizing: border-box;
    padding-top: 24px;
}

.m-division-text {
    box-sizing: border-box;
    padding-top: 24px;
    position: relative;
    max-width: 180px;
    display: flex;
    flex-direction: column;
}

.m-division-text h3 {
    position: relative;
    font-size: 1em;
    margin-bottom: 12px;
    color: white;
}

.m-division-text p {
    position: relative;
    font-size: 1em;
    color: white;
}

.m-division-bg {
    width: 110vw;
    min-width: 360px;
    position: absolute;
    height: 180px;
    background-color: #455158;
    border-radius: 20px;
}