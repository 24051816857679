.m-projects-item {
    margin: 0 auto 12px auto;
    width: 93vw;
    height: 162px;
    background-color: #455158;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding: 20px;
    border-radius: 8px;
}

.m-projects-item h3 {
    font-size: 1em;
}

.m-projects-item p {
    text-align: center;
    max-height: 40px;
    overflow: hidden;
}

.m-projects-item button {
    width: 120px;
    height: 26px;
    font-size: 0.8em;
    color: #455158;
    border-radius: 6px;
    border: none;
}