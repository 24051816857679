.title {
  position: absolute;
  width: 1200px;
  top: 93px;
  left: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #62929e;
  height: 70px;
  z-index: 20;
}
.title > p {
  font-weight: 700 !important;
  font-size: 48px;
  text-transform: uppercase;
  filter: drop-shadow(0px 0px 5px white);
}
.dark {
  margin-left: 20px;
  color: var(--buttons-dark);
  filter: drop-shadow(0px 0px 5px white);
}

@media (max-height: 600px) {
  .title {
    display: none;
  }
}

@media (max-height: 780px) {
  .title {
    display: none;
  }
}
