.container {
  position: absolute;
  /* display: flex;
  justify-content: center;
  align-items: center; */
  width: 1200px;
  top: 60px;
  z-index: 100;
  padding: 0px;
  background: #ffffff9e;
  border-radius: 50%;
  z-index: 5;
  box-sizing: border-box;
  overflow: hidden;
  /* border: 1px solid #c6d9ed; */
}

.line {
  position: absolute;
  top: 360px;
  transition-duration: 600ms;
  transition-timing-function: ease-in-out;
}

.items_top {
  display: flex;
  flex-direction: row;
  position: absolute;
  top: 210px;
  width: 836px;
  left: 182px;
  transition-duration: 300ms;
}
.items_bottom {
  display: flex;
  flex-direction: row;
  position: absolute;
  top: 388px;
  width: 1260px;
  left: -30px;
  transition-duration: 300ms;
}
.item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 418px;
  height: 400px;
}
.item_bottom {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 420px;
  height: 400px;
}
.text {
  width: 380px;
  height: 130px;
  box-sizing: border-box;
  padding: 20px 20px;
  border-radius: 6px;
  background: white;
  font-size: 16px;
  filter: drop-shadow(2px 4px 2px #dbe9f7);
  transition-duration: 400ms;
}
.text:hover {
  transform: scale(1.01);
  background: #f6feff;
}
.title {
  width: 340px;
  text-align: center;
  color: var(--text-dark);
  margin-bottom: 12px;
}
.description {
  width: 340px;
  word-break: break-word;
  text-align: center;
  color: var(--text-dark-lighten3);
}
.img_container {
  width: 160px;
  height: 160px;
  background: #6fbac4;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.img {
  animation-name: image;
  animation-timing-function: ease-in-out;
  animation-duration: 3000ms;
  animation-iteration-count: infinite;
}

.path {
  width: 0px;
  height: 100px;
  border: 4px dashed white;
}

@keyframes image {
  0% {
    transform: translateY(-4px);
  }
  50% {
    transform: translateY(4px);
  }
  100% {
    transform: translateY(-4px);
  }
}