.line {
  position: absolute;
  top: -60px;
  right: 400px;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  animation: bottom_to_screen 1s 1;
  z-index: 400;
}

.item_0 {
  position: absolute;
  top: 349px;
  right: 0px;
}
.item_1 {
  position: absolute;
  top: 647px;
  right: 0px;
}
.item_2 {
  position: absolute;
  top: 964px;
  right: 0px;
}
.item_3 {
  position: absolute;
  top: 1287px;
  right: 0px;
}

.circle {
  z-index: 400;
  position: absolute;
  top: 0px;
  right: 40px;
  width: 220px;
  height: 220px;
  border-radius: 50%;
  background: #6fbac4;
  display: flex;
  justify-content: center;
  align-items: center;
  transition-duration: 500ms;
}

.rectangle {
  z-index: 400;
  position: absolute;
  top: 291px;
  right: -10px;
  width: 378px;
  height: 210px;
  box-sizing: border-box;
  padding: 20px 20px;
  border-radius: 6px;
  background: white;
  color: var(--text-dark);
  font-size: 16px;
  filter: drop-shadow(2px 4px 2px #dbe9f7);
  transition-duration: 400ms;
}

.rectangle:hover {
  transform: scale(1.01);
  background: #f6feff;
}

.title {
  font-size: 24px;
  margin-bottom: 6px;
}

.subtitle {
  font-size: 16px;
  margin-bottom: 6px;
}

.description {
  font-size: 16px;
  color: var(--text-dark-lighten3);
}

@keyframes bottom_to_screen {
  0% {
    transform: translateY(100px);
  }
  100% {
    transform: translateY(0);
  }
}

@media (max-height: 800px) {
  .line {
    top: -100px;
  }
  .rectangle {
    position: absolute;
    top: 251px;
  }
}

@media (max-height: 680px) {
  .line {
    top: -140px;
  }
  .rectangle {
    position: absolute;
    top: 211px;
  }
}

@media (max-height: 580px) {
  .line {
    top: -180px;
  }
  .rectangle {
    position: absolute;
    top: 171px;
  }
}
