.container {
  position: absolute;
  /* display: flex;
  justify-content: center;
  align-items: center; */
  width: 800px;
  height: 800px;
  top: 120px;
  left: 480px;
  z-index: 100;
  padding: 0px;
  background: #ffffff9e;
  border-radius: 50%;
  z-index: 5;
  box-sizing: border-box;
  overflow: hidden;
  /* border: 1px solid #c6d9ed; */
}

.photo {
  position: absolute;
  left: 110px;
  top: 120px;
  width: 600px;
  height: 600px;
}

.left {
  position: absolute;
  top: 356px;
  left: 542px;
  z-index: 20;
  width: 69px;
  height: 69px;
  border-radius: 50%;
  font-size: 42px;
  color: white;
  border: 1px;
  background: #434546;
  line-height: 42px;
  padding-bottom: 5px;
}

.right {
  position: absolute;
  top: 356px;
  right: 0px;
  z-index: 20;
  width: 69px;
  height: 69px;
  border-radius: 50%;
  font-size: 42px;
  color: white;
  border: 1px;
  background: #434546;
  line-height: 42px;
  padding-bottom: 5px;
  outline: none;
}

.left:active,
.right:active {
  transform: scale(0.96);
}

.left:hover,
.right:hover {
  cursor: pointer;
}

.description {
  position: absolute;
  padding: 20px 40px;
  box-sizing: border-box;
  top: 630px;
  left: 636px;
  width: 560px;
  height: 159px;
  background: #fffffffc;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  z-index: 10;
}

.name {
  font-size: 32px;
  color: var(--text-dark);
  margin-bottom: 8px;
}

.position {
  font-size: 16px;
  color: var(--text-dark-lighten3);
  margin-bottom: 8px;
}

.bio {
  font-size: 16px;
  color: var(--text-dark);
}
@media (max-height: 950px) {
  .container {
    transform: scale(0.96);
    top: 60px;
  }
  .description {
    top: 560px;
  }
  .left,
  .right {
    top: 330px;
  }
}

@media (max-height: 800px) {
  .container {
    transform: scale(0.96);
    top: 60px;
  }
  .description {
    top: 540px;
  }
  .left,
  .right {
    top: 330px;
  }
}
@media (max-height: 700px) {
  .container {
    transform: scale(0.9);
    top: -80px;
  }
  .description {
    top: 460px;
  }
  .left,
  .right {
    top: 290px;
  }
}

@media (max-height: 600px) {
  .container {
    transform: scale(0.7);
    top: -120px;
  }
  .description {
    top: 400px;
  }
  .left,
  .right {
    top: 260px;
  }
}

@media (max-height: 570px) {
  .container {
    transform: scale(0.5);
    top: -120px;
  }
  .description {
    top: 370px;
  }
  .left,
  .right {
    top: 240px;
  }
}

@media (max-height: 540px) {
  .container {
    transform: scale(0.5);
    top: -150px;
  }
  .description {
    top: 330px;
  }
  .left,
  .right {
    top: 210px;
  }
}
