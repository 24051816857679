.m-main {
    position: relative;
    height: 786px;
    background-color: rgb(27, 27, 27);
    width: 100vw;
    overflow: hidden;
}

.m-video {
    position: absolute;
    top: 0px;
    width: 1920px;
    left: calc((1920px - 100vw)*-0.5);
    filter: brightness(0.6);
}

.m-main-logo {
    width: 100%;
    height: 212px;
    position: relative;
    margin-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.m-main-logo img {
    width: 212px;
    height: 212px;
}
.m-main-description {
    width: 100%;
    height: 100px;
    position: relative;
    box-sizing: border-box;
    margin-top: 100px;
    padding: 0 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.m-main-description p {
    text-align: center;
    color: white;
    font-size: 1em;
}
.m-main-contacts {
    width: 100%;
    height: 180px;
    position: relative;
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
}
.m-main-contact {
    width: 95%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
}
.m-main-name {
    text-align: center;
    margin-bottom: 4px;
}
.m-main-position {
    text-align: center;
    margin-bottom: 8px;
}
.m-main-phone {
    text-align: center;
}