.main_info {
  display: flex;
  margin-left: 10px;
  font-size: 1em;
  flex-direction: row;
  width: 264px;
  justify-content: space-between;
  color: var(--text-bright);
  margin-right: 130px;
  transition-property: color;
  transition-duration: 1100ms;
}
