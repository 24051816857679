.m-customs-item-card {
    position: relative;
    width: 100vw;
    height: calc(60vw + 300px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding: 20px 0;
}

.m-customs-item-icon {
    width: 60vw;
    height: 60vw;
}

.m-customs-item-main {
    background-color: white;
    width: 267px;
    height: 80px;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    font-weight: bold;
    line-height: 24px;
}

.m-customs-item-subtitle {
    color: #49525e;
}

.m-customs-item-subtitle-second {
    color:#B3B3B3
}

.m-customs-item-sub {
    background-color: white;
    width: 267px;
    height: 140px;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #49525e;
    font-size: 16px;
    line-height: 16px;
}

.m-customs-item-sub > p {
    width: 219px;
    text-align: center;
    line-height: 16px;
    font-size: 16px;
}