.m-header {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
    max-width: 100%;
    height: 40px;
    background-color: #3f3f3f;
    color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    box-sizing: border-box;
    z-index: 1000;
}

.m-header > h3 {
}

.m-header-menu-container {
    width: 100vw;
    max-width: 100%;
    position: relative;
    z-index: 1000;
}