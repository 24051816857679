.admin {
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100000000000;
    background-color: #54697a;
}

.admin-layout {
    margin: 0 auto;
    position: relative;
    width: 1200px;
    height: 100vh;
    display: flex;
}

.admin-menu {
    width: 320px;
    background-color: #465158;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.admin-menu-list {
    width: 100%;    
    overflow-y: scroll;
}

.admin-menu-list::-webkit-scrollbar {
    display: none;
}

.admin-item {
    width: 100%;
    height: 40px;
    color: white;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition-duration: 300ms;
}

.admin-title {
    width: 100%;
    height: 10px;
    color: #657486;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #31434e;
}

.admin-header {
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #466781;
}

.admin-item:hover {
    background-color: rgb(82, 76, 70);
}

.admin-content {
    width: 880px;
    background-color: #daeaf7;
    height: 100%;
    overflow-y: scroll;
}


