.l-textarea {
    position: relative;
    width: 100%;
}

.l-textarea > textarea {
    min-width: 80%;
    min-height: 120px;
    max-width: 95%;
    max-height: 1000px;
    margin-bottom: 24px;
    font-size: 19px;
    padding: 12px;
    box-sizing: border-box;
}

.l-textarea > p {
    width: 100%;
    height: 24px;
    font-size: 16px;
    color: #657486;
    text-align: left;
    padding-left: 12px;
    margin-bottom: 4px;
}