.m-header-item {
    width: 100vw;
    height: 40px;
    background-color: white;
    color: #6d6d6d;
    font-weight: 400px;
    font-size: 1em;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid lightgray;
}