.a-inputs {
  padding-top: 50px;
  margin: 40px auto;
  width: 80%;
  display: flex;
  flex-direction: column;
}

.a-title {
  text-align: left;
  margin: 14px 0px 40px 100px;
  color: white;
  width: 60%;
  font-size: 24px;
}

.a-top-menu {
  width: 880px;
  height: 60px;
  position: fixed;
  background-color: #466781;
  z-index: 100000;
}

.a-save {
  display: flex;
  align-items: center;
  position: absolute;
  top: 9px;
  right: 15px;
  /* margin-top: 20px; */
}

.a-button {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 164px;
  height: 42px;
  color: white;
  background-color: rgb(87 154 207);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition-duration: 300ms;
}
.a-button:active {
  transform: scale(0.95);
}


.a-info {    
  position: absolute;
  right: 178px;
  top: 0px;
  width: 300px;
  height: 42px;
  background-color: rgb(255, 255, 255);
  display: flex;
  align-items: center;
  justify-content: center;
}

.a-success {
  color: rgb(69 229 117);
  transition-duration: 500ms;
}

.a-error {
  color: rgb(216, 64, 64);
  transition-duration: 500ms;
}

.a-warning {
  color: rgb(212, 210, 69);
  transition-duration: 500ms;
}

.a-progress {
  color: rgb(212, 210, 69);
  transition-duration: 500ms;
}

.a-inputs button {
  min-height: 54px;
}