.tag {
  position: absolute;
  top: 100px;
  left: 0px;
  font-size: 16px;
  font-weight: 600;
  color: #657486;
  margin-left: 5px;
  height: 8px;
  z-index: 500;
}
.tag p {
  font-size: 16px;
  font-weight: 700;
}
.title {
  position: absolute;
  top: 110px;
  left: -2px;
  display: flex;
  flex-direction: row;
  color: #62929e;
  height: 110px;
  z-index: 20;
}
.title > p {
  font-weight: 700 !important;
  font-size: 64px;
  text-transform: uppercase;
  filter: drop-shadow(0px 0px 5px white);
}
.dark {
  margin-left: 20px;
  color: var(--buttons-dark);
  filter: drop-shadow(0px 0px 5px white);
}
.team_description {
  position: absolute;
  top: 189px;
  left: 2px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: #657486;
  font-weight: 500;
  font-size: 16px;
  width: 484px;
  z-index: 20;
}

@media (max-height: 700px) {
  .title {
  }
  .title > p {
    font-size: 42px;
  }
  .team_description {
    top: 165px;
    left: 1px;
  }
}
@media (max-height: 600px) {
  .title {
  }
  .title > p {
    font-size: 36px;
  }
  .team_description {
    top: 154px;
    left: 0px;
  }
}
