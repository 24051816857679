.main {
  width: 100vw;
  /* height: calc(100vh - 60px); */
  overflow: hidden;
  background-color: #121212;
}

.main_video {
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  /* height: calc(100vh - 60px); */
  overflow: hidden;
  filter: brightness(0.8);
}

.main_layout {
  position: relative;
  margin: 0 auto;
  width: 1200px;
  height: 100vh;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.main_title {
  margin-top: 30vh;
  width: 1200px;
}
.main_tag > p {
  font-size: 47px;
  text-transform: uppercase;
  font-weight: 700;
  margin-top: 0.7em;
  margin-left: 9px;
}

.main_description {
  font-size: 16px;
  height: 100px;
  left: -2px;
  position: absolute;
  text-shadow: 1px 1px 24px #000000c9;
  top: 186px;
  width: 100%;
  line-height: 22px;
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}
