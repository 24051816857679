.container {
  position: relative;
  width: 100vw;
  height: 100vh;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 1) 21%,
    rgba(235, 245, 255, 1) 32%,
    rgba(235, 245, 255, 1) 62%,
    rgba(219, 233, 247, 1) 72%
  );
}

.mask {
  position: absolute;
  top: 40px;
  right: -80px;
  width: calc(100vw + 80px);
  height: 140vh;
  min-height: 800px;
  mask-repeat: no-repeat;
  mask: url(./mask.svg);
  /* mask-mode: alpha;
  -webkit-mask-type: alpha; */
  mask-position: right -178px;
  -webkit-mask-position: right -178px;
  z-index: 1;
}

.background_layout {
  position: relative;
  width: 100%;
  max-width: 1200px;
  height: 100%;
  margin: 0 auto;
  z-index: 1;
}

.feedback {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  z-index: 999999999999999;
}

.circle {
  position: absolute;
  width: 780px;
  height: 780px;
  border-radius: 50%;
  right: -80px;
  top: 100px;
  background: white;
  z-index: 1;

  mask: url(./circle-mask.svg);
  mask-mode: alpha;
  -webkit-mask-type: alpha;
}

.inner__sea_line {
  position: absolute;
  top: 486px;
  width: 780px;
  height: 100px;
  background: #84b7bf;
  z-index: 1;
}

.inner__rails_top_line {
  position: absolute;
  top: 630px;
  width: 780px;
  height: 6px;
  background: #89929a;
  z-index: 1;
}
.inner__rails_bottom_line {
  position: absolute;
  top: 636px;
  width: 780px;
  height: 10px;
  background: #dfdfdf;
  z-index: 1;
}
.inner__road_line {
  position: absolute;
  bottom: 0px;
  width: 780px;
  height: 120px;
  background: #54697a;
  z-index: 1;
}

.outer__sea_line {
  position: absolute;
  top: 586px;
  width: calc(50% + 200px);
  height: 100px;
  background: #84b7bf;
  z-index: 1;
}
.outer__ground_line {
  position: absolute;
  top: 686px;
  width: calc(50% + 200px);
  height: 120px;
  background: white;
  z-index: 1;
}
.outer__rails_top_line {
  position: absolute;
  top: 730px;
  width: calc(50% + 200px);
  height: 6px;
  background: #89929a;
  z-index: 1;
}
.outer__rails_bottom_line {
  position: absolute;
  top: 736px;
  width: calc(50% + 200px);
  height: 10px;
  background: #dfdfdf;
  z-index: 1;
}
.outer__road_line {
  position: absolute;
  top: 760px;
  width: calc(50% + 290px);
  height: 120px;
  background: #54697a;
  z-index: 1;
}

.cloud_1 {
  position: absolute;
  top: 60px;
  left: 100vw;
  animation: right_to_left 32s linear infinite;
  will-change: transform;
  z-index: 3;
}

.cloud_2 {
  position: absolute;
  top: 160px;
  left: 100vw;
  animation: right_to_left 32s linear 8s infinite;
  will-change: transform;
  z-index: 3;
}

.cloud_3 {
  position: absolute;
  top: 120px;
  left: 100vw;
  animation: right_to_left_scaleX 32s linear 16s infinite;
  will-change: transform;
  z-index: 3;
}

.cloud_4 {
  position: absolute;
  top: 210px;
  left: 100vw;
  animation: right_to_left_scaleX 32s linear 24s infinite;
  will-change: transform;
  z-index: 3;
}

.tree_1 {
  position: absolute;
  top: 580px;
  right: -20px;
  animation: right_to_left 32s linear infinite;
  will-change: transform;
  z-index: 2;
}

.tree_2 {
  position: absolute;
  top: 580px;
  right: -20px;
  animation: right_to_left 32s linear 16s infinite;
  will-change: transform;
  z-index: 2;
}

.tree_3 {
  position: absolute;
  top: 616px;
  right: -20px;
  animation: right_to_left 32s linear 8s infinite;
  will-change: transform;
  z-index: 2;
}

.tree_4 {
  position: absolute;
  top: 616px;
  right: -20px;
  animation: right_to_left 32s linear 24s infinite;
  will-change: transform;
  z-index: 2;
}
.circle_mask {
  position: absolute;
  top: 413px;
  right: -180px;
  z-index: 10;
  display: none;
}

.road_paints {
  position: absolute;
  right: -800px;
  top: 0px;
  width: calc(50vw + 1400px);
  min-width: 1200px;
  height: 1000px;
  overflow: hidden;
  animation: slide_right_to_left_skew 3.3s linear infinite;
  will-change: transform;
  z-index: 2;
}

/* @media (min-width: 1600px) {
  .road_paints {
    width: calc(50vw + 1300px);
  }
}
@media (min-width: 6000px) {
  .road_paints {
    width: calc(50vw + 1500px);
  }
} */
.road_paint {
  position: absolute;
  height: 3px;
  width: 10%;
  transform: skew(20deg);
  background: white;
  z-index: 2;
}

.road_paint_1 {
  top: 740px;
  left: 0%;
  z-index: 2;
}

.road_paint_2 {
  top: 740px;
  left: 12%;
  z-index: 2;
}
.road_paint_3 {
  top: 740px;
  left: 24%;
  z-index: 2;
}
.road_paint_4 {
  top: 740px;
  left: 36%;
  z-index: 2;
}
.road_paint_5 {
  top: 740px;
  left: 48%;
  z-index: 2;
}
.road_paint_6 {
  top: 740px;
  left: 60%;
  z-index: 2;
}
.road_paint_7 {
  top: 740px;
  left: 72%;
  z-index: 2;
}
.road_paint_8 {
  top: 740px;
  left: 84%;
  z-index: 2;
}
.road_paint_9 {
  top: 740px;
  left: 96%;
  z-index: 2;
}
.road_paint_10 {
  top: 740px;
  left: 108%;
  z-index: 2;
}
.road_paint_11 {
  top: 740px;
  left: 120%;
  z-index: 2;
}
.road_paint_12 {
  top: 740px;
  left: 132%;
  z-index: 2;
}

.road_paint_bottom_1 {
  top: 810px;
  left: -4%;
  z-index: 2;
}

.road_paint_bottom_2 {
  top: 810px;
  left: 8%;
  z-index: 2;
}
.road_paint_bottom_3 {
  top: 810px;
  left: 20%;
  z-index: 2;
}
.road_paint_bottom_4 {
  top: 810px;
  left: 32%;
  z-index: 2;
}
.road_paint_bottom_5 {
  top: 810px;
  left: 44%;
  z-index: 2;
}
.road_paint_bottom_6 {
  top: 810px;
  left: 56%;
  z-index: 2;
}
.road_paint_bottom_7 {
  top: 810px;
  left: 68%;
  z-index: 2;
}
.road_paint_bottom_8 {
  top: 810px;
  left: 80%;
  z-index: 2;
}
.road_paint_bottom_9 {
  top: 810px;
  left: 92%;
  z-index: 2;
}
.road_paint_bottom_10 {
  top: 810px;
  left: 104%;
  z-index: 2;
}
.road_paint_bottom_11 {
  top: 810px;
  left: 116%;
  z-index: 2;
}

.road_paint_bottom_12 {
  top: 810px;
  left: 128%;
  z-index: 2;
}

.plane {
  position: absolute;
  top: 140px;
  left: -50vw;
  animation: left_to_right 20s linear infinite;
  -moz-animation: left_to_right 20s linear infinite;
  will-change: transform;
  z-index: 3;
}

.train_container {
  position: absolute;
  right: 1000px;
  top: 610px;
  width: 639px;
  height: 85px;
  animation: train_income 5s ease-in-out;
  will-change: transform;
  z-index: 2;
}

.train {
  width: 100%;
  height: 100%;
  animation: train_loop 30s ease-in-out infinite;
  will-change: transform;
  z-index: 2;
}

.car_container {
  position: absolute;
  left: 300px;
  top: 710px;
  width: 361px;
  height: 121px;
  transform: scaleX(-1);
  animation: car_income 6s ease-in-out;
  will-change: transform;
  z-index: 3;
}

.car {
  width: 100%;
  height: 100%;
  animation: car_loop 14s ease-in-out infinite;
  -moz-animation: car_loop 14s ease-in-out infinite;
  will-change: transform;
  z-index: 3;
}

.background {
  position: absolute;
  top: 185px;
  right: 0px;
  z-index: 1;
}

.ship {
  position: absolute;
  top: 341px;
  z-index: 2;
  right: 0;
  transform: scale(0.8);
  z-index: 3;
}

.crane {
  position: absolute;
  width: 426px;
  height: 589px;
  transform: scale(0.41);
  top: 104px;
  right: -60px;
  z-index: 3;
}

@keyframes car_income {
  0% {
    transform: scaleX(-1) translateX(1200px);
  }
  100% {
    transform: scaleX(-1) translateX(0);
  }
}

@keyframes car_loop {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-50px);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes train_income {
  0% {
    transform: translateX(-500px);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes train_loop {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-100px);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes left_to_right {
  0% {
    transform: translateX(-300px);
  }
  100% {
    transform: translateX(150vw);
  }
}

@keyframes right_to_left {
  0% {
    transform: translateX(0px);
  }
  100% {
    transform: translateX(calc(-150vw - 200px));
  }
}

@keyframes right_to_left_scaleX {
  0% {
    transform: scaleX(-1) translateX(0px);
  }
  100% {
    transform: scaleX(-1) translateX(calc(150vw + 200px));
  }
}

@keyframes slide_right_to_left_skew {
  0% {
    transform: skew(-45deg) translateX(0px) translateZ(0px);
  }
  100% {
    transform: skew(-45deg) translateX(-12%) translateZ(1px);
  }
}

@media (max-height: 850px) {
  .container {
    top: -60px;
  }

  .cloud_1 {
    top: 120px;
  }

  .cloud_2 {
    top: 160px;
  }

  .cloud_3 {
    top: 180px;
  }

  .cloud_4 {
    top: 270px;
  }
}

@media (max-height: 790px) {
  .container {
    top: -100px;
  }

  .cloud_1 {
    top: 160px;
  }

  .cloud_2 {
    top: 200px;
  }

  .cloud_3 {
    top: 220px;
  }

  .cloud_4 {
    top: 310px;
  }
}

@media (max-height: 690px) {
  .container {
    top: -200px;
  }

  .cloud_1 {
    top: 260px;
  }

  .cloud_2 {
    top: 300px;
  }

  .cloud_3 {
    top: 320px;
  }

  .cloud_4 {
    top: 410px;
  }

  .plane {
    top: 280px;
  }
}
