.m-title {
    position: relative;
    width: 100vw;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2em;
}

.m-title-first {
    margin-right: 10px;
}

.m-title-first-two-lines {
    margin-right: 0px;
}

.m-title-second {
    color: #49525e;
}

.m-title-third {
    margin-top: 12px;
    font-size: 0.8em;
}

.m-title-two-lines {
    flex-direction: column;
}