.container {
  position: relative;
  width: 100vw;
  height: calc(100vh - 60px);
  padding-top: 60px;
  /* top: -60px; */
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 1) 21%,
    rgba(235, 245, 255, 1) 32%,
    rgba(235, 245, 255, 1) 62%,
    rgba(219, 233, 247, 1) 72%
  );
  overflow: hidden;
}

.cloud_1 {
  position: absolute;
  top: 80px;
  left: 100vw;
  animation: right_to_left 16s linear infinite;
  will-change: transform;
}

.cloud_2 {
  position: absolute;
  top: 180px;
  left: 100vw;
  animation: right_to_left 16s linear 4s infinite;
  will-change: transform;
}

.cloud_3 {
  position: absolute;
  top: 160px;
  left: 100vw;
  animation: right_to_left_scaleX 16s linear 8s infinite;
  will-change: transform;
}

.cloud_4 {
  position: absolute;
  top: 250px;
  left: 100vw;
  animation: right_to_left_scaleX 16s linear 12s infinite;
  will-change: transform;
}

.cloud_5 {
  position: absolute;
  bottom: 20px;
  left: 100vw;
  animation: right_to_left 16s linear 2s infinite;
  will-change: transform;
}

.cloud_6 {
  position: absolute;
  bottom: 120px;
  left: 100vw;
  animation: right_to_left 16s linear 6s infinite;
  will-change: transform;
}

.cloud_7 {
  position: absolute;
  bottom: 80px;
  left: 100vw;
  animation: right_to_left_scaleX 16s linear 10s infinite;
  will-change: transform;
}

.cloud_8 {
  position: absolute;
  bottom: 170px;
  left: 100vw;
  animation: right_to_left_scaleX 16s linear 14s infinite;
  will-change: transform;
}

.background_layout {
  position: relative;
  width: 100%;
  max-width: 1200px;
  height: 100%;
  margin: 0 auto;
}

.wings_container {
  transition-duration: 300ms;
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: 800px;
  height: 255px;
  z-index: 12;
}

.wings {
  transition-duration: 300ms;
  width: 100%;
  height: 100%;
}
.plane_income_container {
  position: absolute;
  left: -90px;
  bottom: 130px;
  width: 800px;
  height: 255px;
  z-index: 12;
  animation-name: bottom_to_screen;
  animation-duration: 3s;
  transform: scale(0.8);
}

.plane_container {
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: 800px;
  height: 255px;
  animation-name: bottom_to_top_cycle;
  animation-duration: 7s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

.body_container {
  transition-duration: 300ms;
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: 800px;
  height: 255px;
  z-index: 12;
}
.body {
  transition-duration: 300ms;
  width: 100%;
  height: 100%;
}

@keyframes bottom_to_screen {
  0% {
    transform: scale(0.8) translateY(300px);
  }
  100% {
    transform: scale(0.8) translateY(0);
  }
}

@keyframes bottom_to_top_cycle {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(30px);
  }
  100% {
    transform: translateY(0px);
  }
}
@keyframes right_to_left {
  0% {
    transform: translateX(0px);
  }
  100% {
    transform: translateX(calc(-100vw - 200px));
  }
}

@keyframes right_to_left_scaleX {
  0% {
    transform: scaleX(-1) translateX(0px);
  }
  100% {
    transform: scaleX(-1) translateX(calc(100vw + 200px));
  }
}

@media (max-height: 780px) {
  .wings_container {
    transition-duration: 300ms;
    position: absolute;
    left: 0px;
    bottom: -100px;
    width: 800px;
    height: 255px;
    z-index: 12;
  }

  .wings {
    transition-duration: 300ms;
    width: 80%;
    height: 80%;
  }
  .body_container {
    transition-duration: 300ms;
    position: absolute;
    left: 0px;
    bottom: -100px;
    width: 800px;
    height: 255px;
    z-index: 12;
  }
  .body {
    transition-duration: 300ms;
    width: 80%;
    height: 80%;
  }
}

@media (max-height: 650px) {
  .wings_container {
    transition-duration: 300ms;
    position: absolute;
    left: 0px;
    bottom: -270px;
    width: 800px;
    height: 255px;
    z-index: 12;
  }

  .wings {
    transition-duration: 300ms;
    width: 50%;
    height: 50%;
  }
  .body_container {
    transition-duration: 300ms;
    position: absolute;
    left: 0px;
    bottom: -270px;
    width: 800px;
    height: 255px;
    z-index: 12;
  }
  .body {
    transition-duration: 300ms;
    width: 50%;
    height: 50%;
  }
}
