.a-login {
    width: 100vw;
    height: 100vh;
    background-color: #0e0e0e;
    display: flex;
    justify-content: center;
    align-items: center;
}

.a-login > div {
    width: 600px;
    height: 280px;
    background-color: white;
    border: 1px solid black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.a-login input {
    height: 52px;
    width: 80%;
    font-size: 16px;
    padding: 12px;
    box-sizing: border-box;
    margin-bottom: 44px;
}

.a-login button {
    width: 30%;
    height: 52px;
    cursor: pointer;
}