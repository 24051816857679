.l-map-point {
    position: absolute;
    width: 20px;
    height: 20px;
    background-color: blue;
    border-radius: 50%;
    z-index: 9999999999999999999999;
}

.l-map-point img {
    position: absolute;
    top: 0px;
    width: 100%;
    height: 100%;
}