.l-project {
  border: 1px solid gray;
  padding: 20px;
  margin-bottom: 50px;
  background-color: white;
}

.l-file-input {
  width: 80%;
  min-height: 40px;
  background-color: white;
  border: 1px black solid;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 24px;
  cursor: pointer;
}

.l-list-items { 
  padding-top: 24px;
}