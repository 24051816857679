.tag {
  position: absolute;
  top: 97px;
  left: -1px;
  font-size: 16px;
  font-weight: 600;
  color: #657486;
  margin-left: 5px;
  height: 8px;
  z-index: 500;
}
.tag p {
  font-size: 16px;
  font-weight: 700;
}
.title {
  position: absolute;
  top: 110px;
  left: 0px;
  display: flex;
  flex-direction: row;
  color: #62929e;
  height: 110px;
  z-index: 500;
}
.title > p {
  font-weight: 700 !important;
  font-size: 64px;
  filter: drop-shadow(0px 0px 5px white);
}
.dark {
  margin-left: 20px;
  color: var(--buttons-dark);
  filter: drop-shadow(0px 0px 5px white);
}

@media (max-height: 850px) {
  .title {
    top: 170px;
    /* left: 333px; */
    z-index: 1000;
  }
  .title > p {
    /* font-size: 36px; */
    z-index: 1000;
  }
  .tag {
    /* left: 329px; */
    top: 157px;
    z-index: 1000;
  }
}

@media (max-height: 790px) {
  .title {
    top: 210px;
    /* left: 333px; */
    z-index: 1000;
  }
  .title > p {
    /* font-size: 36px; */
    z-index: 1000;
  }
  .tag {
    /* left: 329px; */
    top: 197px;
    z-index: 1000;
  }
}

@media (max-height: 690px) {
  .title {
    top: 310px;
    /* left: 333px; */
    z-index: 1000;
  }
  .title > p {
    /* font-size: 36px; */
    z-index: 1000;
  }
  .tag {
    /* left: 329px; */
    top: 297px;
    z-index: 1000;
  }
}

@media (max-height: 650px) {
  .title {
    left: 333px;
    top: 340px;
    z-index: 1000;
  }
  .title > p {
    font-size: 36px;
    z-index: 1000;
  }
  .tag {
    left: 329px;
    top: 325px;
    z-index: 1000;
  }
}

@media (max-height: 550px) {
  .title {
    left: 333px;
    top: 310px;
    z-index: 1000;
  }
  .title > p {
    font-size: 36px;
    z-index: 1000;
  }
  .tag {
    left: 329px;
    top: 295px;
    z-index: 1000;
  }
}
