.m-container {
    width: 100vw;
    max-width: 100%;
    height: calc(100vh - 40px);
    max-height: calc(100% - 40px);
    overflow-y: scroll;
}

.m-container::-webkit-scrollbar {
    display: none;
}

.m-container-padding {
    width: 100%;
    height: 40px;
}